import { CloseOutlined, PrinterOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { TextFinder } from 'ui'

import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import EligibilityResponse from '../../shared/EligibilityResponse'

import './EligibilityResponseModal.scss'

interface EligibilityResponseModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const EligibilityResponseModal: FC<EligibilityResponseModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { setVerificationResponse, verificationResponse } =
    useEligibilityContext()

  const { setPatientEligibilityResponse } = usePatientEligibilityDetailContext()

  const responseElementRef = useRef<HTMLElement | null>(null)

  const handlePrintResponse = useReactToPrint({
    content: () => responseElementRef.current,
  })

  const handleModalClose = () => {
    setVerificationResponse(null)
    setPatientEligibilityResponse(null)
    setIsOpen(false)
  }

  const modalHeader = (
    <section className='eligibility-response-modal__header'>
      <h4>Patient Verification</h4>
      <TextFinder elementRef={responseElementRef} />
      <div
        aria-label='print verification'
        className='eligibility-response-modal__header-print'
        onClick={handlePrintResponse}
      >
        <PrinterOutlined />
      </div>
      <div
        aria-label='close verification'
        className='eligibility-response-modal__header-close'
        onClick={handleModalClose}
      >
        <CloseOutlined />
      </div>
    </section>
  )

  return (
    <Modal
      className='eligibility-response-modal'
      closable={false}
      destroyOnClose
      footer={null}
      onCancel={handleModalClose}
      open={isOpen}
      title={modalHeader}
      width='90vw'
      bodyStyle={{ maxHeight: window.innerHeight - 100 }}
    >
      <EligibilityResponse
        ref={responseElementRef}
        response={verificationResponse}
      />
    </Modal>
  )
}

export default EligibilityResponseModal
