import {
  CheckCircleTwoTone,
  PauseCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons'
import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'

import { AttachmentStatusFilters } from '../AttachmentsTypes'

import './AttachmentsGrid.scss'

import { VyneButton } from '@vynedental/design-system'

interface QuickFilterItem {
  activeType: string
  icon: ReactNode
  label: string
  status: string
}

interface AttachmentStatusFilterProps {
  filters: AttachmentStatusFilters
  setFilters: Dispatch<SetStateAction<AttachmentStatusFilters>>
}

const AttachmentStatusFilter: FC<AttachmentStatusFilterProps> = ({
  filters,
  setFilters,
}) => {
  const [filter, setFilter] = useState<string | null>(filters.status)

  const handleFilterChange = (status: string) => {
    if (filter === status) {
      setFilter(null)
      setFilters({ ...filters, status: null })
    } else {
      setFilter(status)
      setFilters({ ...filters, status: status })
    }
  }

  const filterOptions: QuickFilterItem[] = [
    {
      activeType: 'fail',
      icon: <WarningTwoTone twoToneColor='#F5222D' />,
      label: 'Needs Attention',
      status: 'Needs Attention - Add Required',
    },
    {
      activeType: 'hold',
      icon: <PauseCircleTwoTone twoToneColor='#729DE4' />,
      label: 'Holding',
      status: 'On Hold',
    },
    {
      activeType: 'ready',
      icon: <CheckCircleTwoTone twoToneColor='#91C73D' />,
      label: 'Ready',
      status: 'Ready to Send',
    },
  ]

  return (
    <section className='quick-filters'>
      {filterOptions.map((option) => (
        <VyneButton
          className={`quick-filters__status-filter-${option.activeType} ${
            filter === option.status
              ? `quick-filters__status-filter-${option.activeType}--active`
              : ''
          }`}
          dataTestId={`quick-filters__status-filter-${option.activeType}`}
          icon={option.icon}
          key={option.label}
          onClick={() => handleFilterChange(option.status)}
        >
          {option.label}
        </VyneButton>
      ))}
    </section>
  )
}

export default AttachmentStatusFilter
