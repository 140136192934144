import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { FC } from 'react'

interface TextFinderNavProps {
  currentMatchIndex: Observable<number>
  matchesTotal: Observable<number>
  textMatches: Observable<HTMLCollectionOf<Element> | null | undefined>
}

const TextFinderNav: FC<TextFinderNavProps> = observer(
  ({ currentMatchIndex, matchesTotal, textMatches }) => {
    const matchIndex = currentMatchIndex.get()
    const total = matchesTotal.get()
    const matches = textMatches.get()

    // navigate through match highlights
    const handleMatchNav = (value: 'up' | 'down') => {
      if (!matches) return

      const currentMatch = matches[matchIndex - 1]

      let nextMatch: HTMLElement | null = null
      switch (value) {
        case 'up': {
          if (matchIndex > 1 && matchIndex <= total) {
            currentMatchIndex.set((i) => i - 1)
            nextMatch = matches[matchIndex - 2] as unknown as HTMLElement
          } else if (matchIndex === 1) {
            currentMatchIndex.set(total)
            nextMatch = matches[total - 1] as unknown as HTMLElement
          }
          break
        }
        case 'down': {
          if (matchIndex > 0 && matchIndex < total) {
            currentMatchIndex.set((i) => i + 1)
            nextMatch = matches[matchIndex] as unknown as HTMLElement
          } else if (matchIndex === total) {
            currentMatchIndex.set(1)
            nextMatch = matches[0] as unknown as HTMLElement
          }
          break
        }
      }

      currentMatch?.classList.remove('search-text-highlight--current')
      currentMatch?.removeAttribute('id')
      nextMatch?.classList.add('search-text-highlight--current')
      nextMatch?.setAttribute('id', 'current-result')

      nextMatch?.focus()
    }

    return (
      <>
        <UpOutlined
          aria-label='next match up'
          onClick={() => handleMatchNav('up')}
        />
        <DownOutlined
          aria-label='next match down'
          onClick={() => handleMatchNav('down')}
        />
      </>
    )
  },
)

export default TextFinderNav
