import { AxiosError, AxiosResponse } from 'axios'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Dispatch, FC, ReactElement, SetStateAction, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { LoadingSpinner } from 'ui'

import { TokenResponse } from 'trellis:api/attachment-standalone/attachment-standalone-client'
import {
  SingleUseTokenLogin,
  SsoTokenLogin,
} from 'trellis:api/authentication/authenticationApi'
import {
  getLoginRedirectURL,
  getPostLoginQueryString,
  processLoginResponse,
} from 'trellis:features/authentication/Login/utils/login-helpers'
import GlobalState from 'trellis:state/globalState'
import {
  getQueryParamAndDelete,
  getSearchParamsLowerCase,
} from 'trellis:utilities/general'

const LoginSSO: FC<{ setShowOutlet: Dispatch<SetStateAction<boolean>> }> = ({
  setShowOutlet,
}): ReactElement => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const searchParamsLowerCase = getSearchParamsLowerCase(searchParams)
  const remoteLiteToken = getQueryParamAndDelete(searchParamsLowerCase, 'eat')
  const ssoToken = getQueryParamAndDelete(searchParamsLowerCase, 'token')
  const ldClient = useLDClient()

  useEffect(() => {
    if (ssoToken) login(() => SsoTokenLogin(ssoToken))
    else if (remoteLiteToken) login(() => SingleUseTokenLogin(remoteLiteToken))
    else {
      if (location?.pathname?.toLowerCase().includes('sso')) navigate('/')
      setShowOutlet(true)
    }
  }, [])

  const login = async (
    method: () => Promise<AxiosResponse<TokenResponse, any>>,
  ): Promise<void> => {
    GlobalState.AuthLoading.set(true)

    try {
      const { data } = await method()
      if (data) await processLoginResponse(data, ldClient)
      setSearchParams(searchParamsLowerCase)

      //TODO: find a way to check if the route exists so we can send them to claims if they come over with an invalid path

      //only redirect them if they're sso or have a returnurl, let them go to the original location otherwise
      if (
        location?.pathname?.toLowerCase().includes('sso') ||
        searchParamsLowerCase.get('returnurl')
      ) {
        const redirectURL = getLoginRedirectURL(searchParamsLowerCase)
        if (redirectURL) {
          navigate(redirectURL, {
            replace: true,
            state: { previousPathname: location.pathname },
          })
        }
      }
    } catch (error: unknown) {
      const queryString = getPostLoginQueryString(searchParamsLowerCase)
      /** TODO:
       * -Figure out if we want to log the user out if they were already logged in
       * and the sso/RL login failed, would kill any other active tabs they have if they triggered a refresh
       */

      // pass error to login
      let errorString: string
      if (error instanceof Object)
        errorString = JSON.stringify({
          ...error,
          response: error instanceof AxiosError ? { ...error.response } : null,
        })
      else errorString = JSON.stringify({ error })

      navigate(`/Account/Login${queryString}`, {
        replace: true,
        state: {
          error: errorString,
        },
      })
    } finally {
      GlobalState.AuthLoading.set(false)
      setShowOutlet(true)
    }
  }

  return <LoadingSpinner />
}

export default LoginSSO
