import { RangePicker } from '@vynedental/design-system'
import { AutoComplete, Input, Select } from 'antd'
import dayjs from 'dayjs'
import { ChangeEvent, FC, useState } from 'react'

import {
  appointmentRangePresets,
  rangePresets,
  rangeThemeConfig,
} from 'trellis:utilities/datePickerUtilities'

import {
  ControlOptionType,
  ObjAnyPropsType,
  sortHeaderDateRangeType,
} from '../../SmartTableTypes'

type SearchControlType = {
  options: any[]
  dateRange: sortHeaderDateRangeType
  updateSearch: (value: string) => void
  search: ObjAnyPropsType
  searchInput: any
  filterLoading: boolean
  openDate: boolean
  setDateFilter: (
    dates: [Date, Date],
    dateString: [string, string],
    confirm: () => void,
  ) => void
  type: string
  property: string
  rangeType: string
  confirm: () => void
  handleSearch: (...args: any) => void
}

const { Option } = Select

const SearchControl: FC<SearchControlType> = ({
  options,
  dateRange,
  updateSearch,
  search,
  searchInput,
  filterLoading,
  openDate,
  setDateFilter,
  property,
  type,
  rangeType,
  confirm,
  handleSearch,
}) => {
  const [foundOptions, setOptions] = useState<ControlOptionType[]>(options)
  let SelectedControl: JSX.Element

  const onSearch = (searchValue: string) => {
    if (searchValue) {
      searchValue = searchValue.toLowerCase()
      const filteredOptions: ControlOptionType[] = options.filter(
        (option: ControlOptionType) =>
          option.value.toLowerCase().includes(searchValue),
      )
      setOptions(filteredOptions)
    } else setOptions(options)

    updateSearch(searchValue)
  }

  switch (type) {
    case 'auto':
      SelectedControl = (
        <AutoComplete
          value={search[property]}
          ref={(node) => (searchInput.current = node)}
          placeholder='Search'
          options={foundOptions}
          onSelect={(value: string) => {
            updateSearch(value), handleSearch(value, confirm)
          }}
          onSearch={onSearch}
          style={{ width: 400 }}
        />
      )
      break
    case 'text':
      SelectedControl = (
        <Input
          value={search[property]}
          placeholder='Search'
          ref={(node) => (searchInput.current = node)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            updateSearch(e.target.value)
          }
          onPressEnter={(e: any) => handleSearch(e.target.value, confirm)}
          style={{ width: 400 }}
        />
      )
      break
    case 'select':
      SelectedControl = (
        <Select
          style={{ width: 400 }}
          value={search[property]}
          ref={(node) => (searchInput.current = node)}
          onChange={(value: string) => {
            updateSearch(value), handleSearch(value, confirm)
          }}
          loading={filterLoading}
        >
          {options.map((option: ControlOptionType, i: number) => (
            <Option
              key={i}
              value={option.value}
            >
              {option.value === 'In Queue' ? 'Ready' : option.value}
            </Option>
          ))}
        </Select>
      )
      break
    case 'range':
      SelectedControl = (
        <RangePicker
          allowClear={false}
          name='column-search-range-picker'
          onChange={(dates, dateStrings) =>
            setDateFilter(
              [dates[0].toDate(), dates[1].toDate()],
              dateStrings,
              confirm,
            )
          }
          presets={
            rangeType === 'future' ? appointmentRangePresets : rangePresets
          }
          theme={rangeThemeConfig}
          value={
            dateRange?.dates[0] && [
              dayjs(dateRange.dates[0]),
              dayjs(dateRange.dates[1]),
            ]
          }
        />
      )
      break
    default:
      SelectedControl = null
      break
  }

  return SelectedControl
}

export default SearchControl
