import { VyneButton } from '@vynedental/design-system'
import { Col, Divider, Form, Row } from 'antd'
import { FC, useState } from 'react'

import { FormInput } from '../../_siteWide/form/formInput'
import api from '../../../utilities/api'
import {
  isDiSite,
  redirectToLogin,
  showMessage,
} from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { validateEmail } from '../../../utilities/validators/emailValidator'

import './forgotPassword.scss'

import { PublicLayoutWrapper } from 'trellis:components/layouts/PreAuthLayout/PreAuthLayout'

type ForgotPasswordT = {
  instructionHeader: string
  instructionText: string
  buttonText: string
}

type ForgotPasswordFormData = {
  Username: string
  errors?: []
  isValid?: false
}

const initialFormData: ForgotPasswordFormData = {
  Username: '',
  errors: [],
}

export const ForgotPassword: FC<Partial<ForgotPasswordT>> = ({
  instructionHeader = '',
  instructionText = "Enter your email and we'll send you a link\nto reset your password.",
  buttonText = 'Submit',
}) => {
  const [userData, setUserData] = useState(initialFormData)
  const [sending, setSending] = useState(false)

  const [form] = Form.useForm()
  form.setFieldsValue(userData)

  const handleForgotPassword = () => {
    setSending(true)
    validateForm(userData)
    if (userData.isValid) {
      submitForgotPassword(userData)
    }
  }

  const validateForm = (formData: any) => {
    formData.isValid = true
    formData.errors = []
    validateProperty(validateEmail, formData, 'Username', '', true)

    if (formData.errors?.length) {
      formData.isValid = false
      setSending(false)
      showMessage('There was a problem. Please fix the errors and try again.')
    }
    setUserData({ ...userData, ...formData })
  }

  const submitForgotPassword = (passwordRequest: ForgotPasswordFormData) => {
    api
      .forgotPassword({
        UserName: passwordRequest.Username,
        AppCompanyName: isDiSite() ? 2 : 0,
      })
      .then(() =>
        showMessage(
          'Please check your email for further instructions.',
          'success',
        ),
      )
      .catch(() =>
        showMessage('An error has occurred. Please try again later.'),
      )
      .finally(() => {
        setSending(false)
        setUserData(initialFormData)
      })
  }

  return (
    <PublicLayoutWrapper>
      <section className='forgot-password--container'>
        <div className='forgot-password__title'>Password Reset</div>
        <Form
          form={form}
          name='ForgotPassword'
          className='forgot-password__form'
        >
          <div className='forgot-password__header'>{instructionHeader}</div>
          <div className='forgot-password__info'>{instructionText}</div>
          <FormInput
            name='Username'
            label='Username (email)'
            element={userData}
            setElement={setUserData}
            initialValue={userData.Username}
            validator={validateEmail}
            required
          />
          <Row justify='center'>
            <Col>
              <VyneButton
                dataTestId='forgot-password-submit-button'
                loading={sending}
                onClick={handleForgotPassword}
                type='primary'
              >
                {buttonText}
              </VyneButton>
            </Col>
            <Divider />
            <Col>
              <VyneButton
                dataTestId='forgot-password-return-button'
                onClick={redirectToLogin}
                type='link'
              >
                Return to Log In
              </VyneButton>
            </Col>
          </Row>
        </Form>
      </section>
    </PublicLayoutWrapper>
  )
}
