import { VyneButton } from '@vynedental/design-system'
import { Col, Divider, Form, Row } from 'antd'
import { FC, useEffect, useState } from 'react'

import { FormPasswordInput } from '../../_siteWide/form/formPasswordInput'
import api from '../../../utilities/api'
import { redirectToLogin, showMessage } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import {
  validateConfirmPassword,
  validatePassword,
} from '../../../utilities/validators/passwordValidators'
import { ForgotPassword } from '../forgotPassword/forgotPassword'

import './resetPassword.scss'

import { PublicLayoutWrapper } from 'trellis:components/layouts/PreAuthLayout/PreAuthLayout'

type ResetPasswordFormData = {
  NewPassword: string
  ConfirmPassword: string
  errors?: []
  isValid?: boolean
}

const initialFormData: ResetPasswordFormData = {
  NewPassword: '',
  ConfirmPassword: '',
  errors: [],
}

export const ResetPassword: FC = () => {
  const [passwordData, setPasswordData] = useState(initialFormData)
  const [sending, setSending] = useState(false)
  const [token, setToken] = useState('')
  const [linkExpired, setLinkExpired] = useState(true)

  const [form] = Form.useForm()
  form.setFieldsValue(passwordData)

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code') || ''
    api.verifyPasswordToken({ token: code }).then(() => {
      setLinkExpired(false)
      setToken(code)
    })
  }, [])

  const handleResetPassword = () => {
    setSending(true)
    validateForm(passwordData)
    if (passwordData.isValid) submitResetPassword(passwordData)
  }

  const validateForm = (formData: any) => {
    formData.isValid = true
    formData.errors = []
    validateProperty(validatePassword, formData, 'NewPassword', '', true)
    validateProperty(
      validateConfirmPassword,
      formData,
      'ConfirmPassword',
      '',
      true,
    )

    if (formData.errors?.length) {
      formData.isValid = false
      setSending(false)
      showMessage('There was a problem. Please fix the errors and try again.')
    }
    setPasswordData({ ...passwordData, ...formData })
  }

  const submitResetPassword = (passwordRequest: ResetPasswordFormData) => {
    const requestBody: {} = {
      token: token,
      password: passwordRequest.NewPassword,
    }

    api
      .resetPassword(requestBody)
      .then(({ data }) => {
        if (data.message) {
          showMessage(
            'Password was successfuly reset. Redirecting to login...',
            'success',
          )
          setTimeout(() => {
            redirectToLogin()
          }, 1500)
        }
      })
      .catch((error) => {
        const errorDetail = error?.response?.data?.detail
        errorDetail
          ? showMessage(errorDetail)
          : showMessage(
              'There was a problem resetting your password. Please try again later.',
            )
      })
      .finally(() => {
        setSending(false)
      })
  }

  return !linkExpired ? (
    <PublicLayoutWrapper>
      <div className='reset-password--container'>
        <div className='reset-password__title'>New Password</div>
        <Divider />
        <Form
          form={form}
          name='ResetPassword'
          className='reset-password__form'
        >
          <FormPasswordInput
            name='NewPassword'
            label='Create Password'
            element={passwordData}
            setElement={setPasswordData}
            initialValue={passwordData.NewPassword}
            validator={validatePassword}
            width={460}
            required
          />
          <FormPasswordInput
            name='ConfirmPassword'
            label='Confirm Password'
            element={passwordData}
            setElement={setPasswordData}
            initialValue={passwordData.ConfirmPassword}
            validator={validateConfirmPassword}
            width={460}
            required
          />
          <Row
            justify='center'
            className='reset-password__button'
          >
            <Col>
              <VyneButton
                dataTestId='reset-password-submit-button'
                loading={sending}
                onClick={handleResetPassword}
                type='primary'
              >
                Submit
              </VyneButton>
            </Col>
          </Row>
        </Form>
      </div>
    </PublicLayoutWrapper>
  ) : (
    <ForgotPassword
      instructionHeader='Email verification link expired.'
      instructionText='Enter your User ID (email) and click Submit to send a new link.'
      buttonText='Send New Link'
    />
  )
}
