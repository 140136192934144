import { VyneButton } from '@vynedental/design-system'
import { Divider, Spin } from 'antd'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import {
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import { ErrorCodes, LogError } from 'utils'

import { EulaModel } from 'trellis:api/account/account-client'
import { AcceptEula, GetEula } from 'trellis:api/account/accountApi'
import { logoutUser } from 'trellis:features/authentication/Login/utils/login-helpers'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'

import './AcceptEULA.scss'

import { observer } from '@legendapp/state/react'
import { AxiosError } from 'axios'

import { PublicLayoutWrapper } from 'trellis:components/layouts/PreAuthLayout/PreAuthLayout'

export const eulaLoader = () => {
  const response = GlobalState.UserInfo.eulaAccepted.get()

  if (response == undefined) {
    LogError(new Error('Error getting eulaLoader flag'))
    return null
  }

  return response
}

const AcceptEULA: FC = observer(() => {
  const hasEULA = useLoaderData()
  if (hasEULA) return <Navigate to='/' />

  const [eula, setEula] = useState<EulaModel>()
  const [loading, setLoading] = useState<boolean>(false)
  const [disableAccept, setDisableAccept] = useState<boolean>(true)

  const location = useLocation()
  const navigate = useNavigate()
  const ldClient = useLDClient()

  const username = GlobalState.UserInfo.userName.get()

  useEffect(() => {
    const termsLink = document.getElementById('link-terms-of-service')
    termsLink?.addEventListener('click', () => {
      setDisableAccept(false)
    })

    return () => {
      termsLink?.removeEventListener('click', () => {
        setDisableAccept(false)
      })
    }
  }, [eula])

  useEffect(() => {
    const getEula = async () => {
      try {
        const eulaResponse = await GetEula()
        setEula(eulaResponse.data)
      } catch (e) {
        showMessage('There was a problem getting the EULA. Please try again.')
        //we have no way of recovering, log them out
        LogError(e, 'Failed to get EULA')
        logoutUser(ldClient)
      }
    }

    getEula()
  }, [])

  const handleAccept = async () => {
    try {
      await AcceptEula({ eulaVersionId: eula.eulaVersionId, username })

      GlobalState.UserInfo.eulaAccepted.set(true)
      navigate(
        location?.state?.redirectURL ? location?.state?.redirectURL : '/',
      )
    } catch (e) {
      showMessage('There was a problem accepting the EULA. Please try again.')
    }
  }

  const handleDecline = async () => {
    try {
      await logoutUser(ldClient)
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error?.message != ErrorCodes.auth_refresh_failed &&
        error?.response?.status != 403
      )
        LogError(error, 'Error logging out')
    } finally {
      setLoading(false)
      GlobalState.IsAuthenticated.set(false)
      navigate('/Account/Login', { replace: true })
    }
  }

  return (
    <>
      {!eula ? (
        <Spin
          spinning={loading}
          className='eula-accept-content--loading'
        />
      ) : (
        <PublicLayoutWrapper>
          <div className='eula-accept-content'>
            <section
              className='eula-accept-content__text'
              dangerouslySetInnerHTML={{ __html: eula?.eulaText }}
            />
            <Divider />
            <section className='eula-accept-content__controls'>
              <VyneButton
                dataTestId='accept-eula-button'
                disabled={disableAccept}
                onClick={handleAccept}
                type='primary'
              >
                I Accept
              </VyneButton>
              <VyneButton
                danger
                dataTestId='decline-eula-button'
                onClick={handleDecline}
              >
                Decline
              </VyneButton>
            </section>
          </div>
        </PublicLayoutWrapper>
      )}
    </>
  )
})

export default AcceptEULA
