type ValidNPI = {
  isFormat: boolean
  isValid: boolean
  value: string
}

export const validNpi = (npi: string): ValidNPI => {
  const result: ValidNPI = {
    isFormat: true,
    isValid: true,
    value: npi || '',
  }

  if (!npi || npi === '') return result

  result.isFormat = new RegExp('^[1][0-9]{9}$').test(npi)
  if (!result.isFormat) return result

  let sum = 0
  for (let i = npi.length - 2; i >= 0; i--) {
    if (i % 2 === 0) {
      const double = parseInt(npi[i]) * 2
      const doubleString = double.toString()
      sum +=
        doubleString.length > 1
          ? parseInt(doubleString[0]) + parseInt(doubleString[1])
          : parseInt(doubleString)
    } else sum += parseInt(npi[i])
  }

  sum += 24

  const unitsDigit = parseInt(sum.toString()[sum.toString().length - 1])
  const checkDigit = unitsDigit > 0 ? 10 - unitsDigit : unitsDigit
  result.isValid = checkDigit === parseInt(npi[npi.length - 1])

  return result
}

export const validateNPI = (value: string) => {
  const npi: ValidNPI = validNpi(value)
  let error: string | null = null

  if (!npi.isFormat) error = 'Must start with a 1 and be 10 numeric digits'
  if (!npi.isValid) error = 'Invalid NPI'

  if (error) return Promise.reject(new Error(error))
  else return Promise.resolve()
}
