import { computed } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { VyneButton } from '@vynedental/design-system'
import { FC, useEffect } from 'react'

import GlobalState from 'trellis:state/globalState'

import styles from '../../_downloads.module.scss'
import { Download } from '../../utils/downloads-helpers'
import { usePearlDownload } from '../hooks/usePearlDownload'
import { ImageSyncIcon } from './ImageSyncAssets'
import { ImageSyncCredentialsModal } from './ImageSyncCredentialsModal'
import { ImageSyncDownloadInstallerModal } from './ImageSyncDownloadInstallerModal'
import { ImageSyncErrorModal } from './ImageSyncErrorModal'
import { ImageSyncSetupCompleteModal } from './ImageSyncSetupCompleteModal'
import { ImageSystemSelectionModal } from './ImageSystemSelectionModal'

import DownloadIcon from 'trellis:assets/cloud-download-icon.svg?react'

interface ImageSyncDownloadItemProps {
  download: Download
  isAdditional?: boolean
}

type ModalState =
  | 'none'
  | 'enter-credentials'
  | 'credentials-complete'
  | 'select-system'
  | 'download-installer'
  | 'error'
export const ImageSyncDownloadItem: FC<ImageSyncDownloadItemProps> = observer(
  ({ download, isAdditional = false }) => {
    const { status, refreshStatus, handleDirectDownload } = usePearlDownload()

    const activeModal = useObservable<ModalState>('none')
    const selectedImageSystem = useObservable<string>('')

    const customerId = GlobalState.Auth.CustomerIdNumber.get()

    // Add a new state to track loading
    const isDownloading = useObservable<boolean>(false)

    useEffect(() => {
      void refreshStatus(customerId)
    }, [])

    const attemptDirectDownload = async () => {
      const success = await handleDirectDownload(customerId)
      if (!success) {
        activeModal.set('error')
      }
      return success
    }

    const handleDownload = async () => {
      selectedImageSystem.set('')
      const currentStatus = status.get()

      try {
        isDownloading.set(true)
        //either start download or open image system selection modal
        if (currentStatus.hasCredentials) {
          await attemptDirectDownload()
        } else {
          activeModal.set('select-system')
        }
      } catch (error) {
        activeModal.set('error')
      } finally {
        isDownloading.set(false)
      }
    }

    const handleCredentialsEntered = async () => {
      try {
        // First close the credentials modal
        activeModal.set('none')

        // Then refresh and wait
        await refreshStatus(customerId)

        // Finally show the completion modal
        activeModal.set('credentials-complete')
      } catch (error) {
        activeModal.set('error')
      }
    }

    const handleModalClose = async () => {
      activeModal.set('none')
      try {
        await refreshStatus(customerId)
      } catch (error) {
        // Silently handle the error - or we could add proper error handling if needed
      }
    }

    const isModalActive = (modalName: ModalState) =>
      computed(() => activeModal.get() === modalName)

    return (
      <article className={styles['download-item']}>
        <div className={styles['download-item__additional']}>
          <section
            className={`${styles['download-item__card']} ${isAdditional ? styles['download-item__card--additional'] : ''}`}
          >
            <div className={styles['download-item__img']}>
              <ImageSyncIcon />
            </div>

            <div className='flex-column gap-050'>
              <div className='flex-row items-center gap-050'>
                <h6 className={styles['download-item__title']}>
                  {download.title}
                </h6>
                <p className={styles['download-item__version-tag']}>
                  Version: {download.versionNumber}
                </p>
              </div>

              <p className={styles['download-item__notes']}>{download.notes}</p>

              <div className={styles['download-item__guide-container']}>
                {!status.get().hasCredentials && (
                  <div>
                    <a
                      href='#'
                      className={styles['download-item__verify-credentials']}
                      onClick={(e) => {
                        e.preventDefault()
                        activeModal.set('enter-credentials')
                      }}
                    >
                      Verify Credentials
                    </a>
                    <label
                      className={
                        styles['download-item__verify-credentials-label']
                      }
                    >
                      {' '}
                      (Support Use Only)
                    </label>
                  </div>
                )}

                {isAdditional && (
                  <div
                    className={
                      styles['download-item__card--additional-button-container']
                    }
                  >
                    <VyneButton
                      dataTestId='download-image-sync-button'
                      icon={
                        <span
                          className={`anticon ${styles['download-item__icons--vertical-align']}`}
                        >
                          <DownloadIcon />
                        </span>
                      }
                      loading={isDownloading.get()}
                      onClick={() => void handleDownload()}
                      type='primary'
                    >
                      Download
                    </VyneButton>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        <ImageSystemSelectionModal
          isOpen={isModalActive('select-system')}
          onClose={() => activeModal.set('none')}
          onSelectImageSystem={(value) => selectedImageSystem.set(value)}
          onContinue={() => activeModal.set('download-installer')}
        />

        <ImageSyncDownloadInstallerModal
          isOpen={isModalActive('download-installer')}
          onClose={() => void handleModalClose()}
          onFailure={() => activeModal.set('error')}
          imageSystemIdentifier={selectedImageSystem}
        />

        <ImageSyncCredentialsModal
          isOpen={isModalActive('enter-credentials')}
          onSuccess={() => void handleCredentialsEntered()}
          onClose={() => void handleModalClose()}
          onError={() => activeModal.set('error')}
        />

        <ImageSyncSetupCompleteModal
          isOpen={isModalActive('credentials-complete')}
          onClose={() => void handleModalClose()}
        />

        <ImageSyncErrorModal
          isOpen={isModalActive('error')}
          onClose={() => activeModal.set('none')}
          openCredentialsForm={() => activeModal.set('enter-credentials')}
          errorType={status.get().error ? 'GENERAL_ERROR' : undefined}
          errorMessage={status.get().error}
        />
      </article>
    )
  },
)
