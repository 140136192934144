import { computed } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { FC } from 'react'

import styles from '../DataTable.module.scss'
import { DataTablePaginationProps } from '../utils/data-table-types'

// TODO: style select dropdown
// TODO: add an onClick to the ellipses box to do what the click next to it does
export const DataTablePagination: FC<DataTablePaginationProps> = observer(
  ({ pagination$ }) => {
    const { currentPage, dataTotal, pageLimit } = pagination$.get()

    const totalPages$ = computed<number[]>(() => {
      const total = Math.ceil(dataTotal / pageLimit)
      const arr: number[] = []
      for (let i = 1; i <= total; i++) arr.push(i)

      return arr
    })

    const getTruncatedPages = (page: number): number[] => {
      const totalPages = totalPages$.get().length

      const sideWidth = 1
      const leftWidth = (7 - sideWidth * 2 - 3) >> 1
      const rightWidth = (7 - sideWidth * 2 - 2) >> 1

      const range = (start: number, end: number) => {
        return Array.from(Array(end - start + 1), (_, i) => i + start)
      }

      if (totalPages <= 7) {
        // no breaks in list
        return range(1, totalPages)
      }
      if (page <= 7 - sideWidth - 1 - rightWidth) {
        // no break on left of page
        return range(1, 7 - sideWidth - 1).concat(
          0,
          range(totalPages - sideWidth + 1, totalPages),
        )
      }
      if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        return range(1, sideWidth).concat(
          0,
          range(
            totalPages - sideWidth - 1 - rightWidth - leftWidth,
            totalPages,
          ),
        )
      }
      // Breaks on both sides
      return range(1, sideWidth).concat(
        0,
        range(page - leftWidth, page + rightWidth),
        0,
        range(totalPages - sideWidth + 1, totalPages),
      )
    }

    const truncatedPages$ = useObservable<number[]>(
      getTruncatedPages(currentPage),
    )

    const handlePageSelect = (page: number) => {
      pagination$.currentPage.set(page)
      truncatedPages$.set(getTruncatedPages(page))
    }

    const handlePageLimitSelect = (limit: string) => {
      pagination$.set({
        currentPage: 1,
        dataTotal,
        pageLimit: parseInt(limit),
      })
    }

    return (
      <div className={styles['vyne-data-table-pagination']}>
        <p className={styles['vyne-data-table-pagination__total']}>
          Total {dataTotal} items
        </p>
        <div className={styles['vyne-data-table-pagination__controls']}>
          <div
            className={`${styles['vyne-data-table-pagination__box']} ${
              currentPage === 1
                ? styles['vyne-data-table-pagination__box--disabled']
                : ''
            }`}
          >
            <div onClick={() => handlePageSelect(currentPage - 1)}>
              <svg
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_1973_1052)'>
                  <g clipPath='url(#clip1_1973_1052)'>
                    <path
                      d='M8.83938 2.06639V1.03113C8.83938 0.941394 8.73625 0.891841 8.66661 0.946752L2.62911 5.66238C2.57781 5.70227 2.5363 5.75335 2.50775 5.81173C2.4792 5.8701 2.46436 5.93422 2.46436 5.99921C2.46436 6.06419 2.4792 6.12832 2.50775 6.18669C2.5363 6.24506 2.57781 6.29614 2.62911 6.33604L8.66661 11.0517C8.73759 11.1066 8.83938 11.057 8.83938 10.9673V9.93202C8.83938 9.86639 8.80857 9.80345 8.75768 9.76327L3.93625 5.99988L8.75768 2.23514C8.80857 2.19497 8.83938 2.13202 8.83938 2.06639Z'
                      fill='#87878A'
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id='clip0_1973_1052'>
                    <rect
                      width='12'
                      height='12'
                      fill='white'
                    />
                  </clipPath>
                  <clipPath id='clip1_1973_1052'>
                    <rect
                      width='12'
                      height='12'
                      fill='white'
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <ol className={styles['vyne-data-table-pagination__pages']}>
            {truncatedPages$.get().map((page, index) => (
              <div key={`vyne-data-table-pagination`}>
                {page === 0 ? (
                  <li
                    className={`${styles['vyne-data-table-pagination__box']} ${styles['vyne-data-table-pagination__box--ellipses']}`}
                    key={`pagination-ellipses-${index}`}
                  >
                    ...
                  </li>
                ) : (
                  <li
                    className={`${styles['vyne-data-table-pagination__box']} ${
                      styles[
                        `vyne-data-table-pagination__box--${
                          currentPage === page ? 'active' : 'inactive'
                        }`
                      ]
                    }`}
                    key={`pagination-${page}-${index}`}
                    onClick={() => handlePageSelect(page)}
                  >
                    {page}
                  </li>
                )}
              </div>
            ))}
          </ol>
          <div
            className={`${styles['vyne-data-table-pagination__box']} ${
              currentPage === totalPages$.length
                ? styles['vyne-data-table-pagination__box--disabled']
                : ''
            }`}
          >
            <div onClick={() => handlePageSelect(currentPage + 1)}>
              <svg
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_1973_1068)'>
                  <path
                    d='M9.39777 5.663L3.36027 0.947372C3.34449 0.934952 3.32553 0.927233 3.30557 0.925102C3.2856 0.922972 3.26544 0.926515 3.2474 0.935327C3.22936 0.944138 3.21417 0.957859 3.20357 0.974915C3.19298 0.99197 3.18741 1.01167 3.1875 1.03175V2.06701C3.1875 2.13264 3.2183 2.19559 3.2692 2.23576L8.09063 6.0005L3.2692 9.76523C3.21697 9.80541 3.1875 9.86835 3.1875 9.93398V10.9692C3.1875 11.059 3.29063 11.1085 3.36027 11.0536L9.39777 6.338C9.44908 6.29797 9.4906 6.24677 9.51915 6.18828C9.5477 6.1298 9.56254 6.06558 9.56254 6.0005C9.56254 5.93542 9.5477 5.87119 9.51915 5.81271C9.4906 5.75423 9.44908 5.70302 9.39777 5.663Z'
                    fill='#87878A'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1973_1068'>
                    <rect
                      width='12'
                      height='12'
                      fill='white'
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <select
          className={styles['vyne-data-table-pagination__limit-select']}
          defaultValue={!pageLimit ? 50 : pageLimit}
          onChange={(e) => handlePageLimitSelect(e.target.value)}
        >
          <option value='10'>10 / page</option>
          <option value='20'>20 / page</option>
          <option value='50'>50 / page</option>
          <option value='100'>100 / page</option>
        </select>
      </div>
    )
  },
)
