import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import {
  AnimationEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  ErrorRecord,
  spacesToProperty,
  Validation,
  Validations,
  ValidatorReturn,
} from 'utils'

import FormControlGroup from '../FormControlGroup/FormControlGroup'
import styles from './NewFormInput.module.scss'

// includes all native attributes of form input element
type InputProps = JSX.IntrinsicElements['input']

type FormInputProps<DataType> = InputProps & {
  errors?: ErrorRecord<DataType>
  label?: string
  name: string
  setValidations?: Dispatch<SetStateAction<Validations<DataType>>> | null
  showPasswordVisibilityIcon?: boolean
  validator?: ((value: string) => ValidatorReturn) | null
}

export const NewFormInput = <DataType extends object>({
  autoFocus,
  className = '',
  disabled = false,
  errors = {},
  label,
  name,
  placeholder,
  required = false,
  setValidations = null,
  showPasswordVisibilityIcon = false,
  type = 'text',
  validator = null,
  value,
  ...props
}: FormInputProps<DataType>): ReactElement => {
  const [hasAutoFill, setHasAutoFill] = useState<boolean>(false)
  const [inputType, setInputType] = useState<string>(type)
  const [isLabelFloating, setIsLabelFloating] = useState<boolean>(
    label && (value || placeholder) ? true : false,
  )
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (inputRef?.current && autoFocus && !hasAutoFill) {
      inputRef?.current?.focus()
    }
  }, [inputRef?.current])

  useEffect(() => {
    if (setValidations) handleSetValidations()
  }, [setValidations])

  useEffect(() => {
    if (value) setIsLabelFloating(true)
  }, [value])

  const handleAutoFill = (e: AnimationEvent) => {
    if (label && e.animationName.includes('on-auto-fill-start')) {
      setHasAutoFill(true)
      setIsLabelFloating(true)
    } else if (autoFocus) inputRef.current?.focus()
  }

  const handleSetValidations = () => {
    const validation: Validation = {}
    if (required)
      validation.required = {
        message: `${spacesToProperty(name)} is required`,
        value: required,
      }
    if (validator) validation.validator = validator

    if (setValidations)
      setValidations((prev) => ({
        ...prev,
        [name]: validation,
      }))
  }

  const handleOnBlur = () => {
    if (label && (value || placeholder)) setIsLabelFloating(true)
    else setIsLabelFloating(false)
  }

  const togglePasswordVisibility = () => {
    if (isPasswordVisible) setInputType('password')
    else setInputType('text')

    setIsPasswordVisible(!isPasswordVisible)
  }

  const classSelectors = `
    ${styles['form-control-group__input']}
    ${
      styles[
        `form-control-group__input--${
          errors[name as keyof DataType] ? 'error' : 'default'
        }`
      ]
    }
    ${className}
  `

  return (
    <FormControlGroup
      errors={errors}
      isLabelFloating={isLabelFloating}
      label={label}
      name={name}
    >
      <input
        className={classSelectors}
        data-testid={`form-control-group-input--${name}`}
        disabled={disabled}
        name={name}
        onAnimationStart={handleAutoFill}
        onBlur={handleOnBlur}
        onFocus={() => label && setIsLabelFloating(true)}
        placeholder={placeholder}
        ref={inputRef}
        type={inputType}
        {...props}
      />
      {showPasswordVisibilityIcon && (
        <div
          className={styles['form-control-group__input-icon']}
          data-testid='toggle-password-visibility'
          onClick={togglePasswordVisibility}
        >
          {isPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </div>
      )}
    </FormControlGroup>
  )
}
