import { VyneRadio } from '@vynedental/design-system'
import { Form, Radio } from 'antd'

import { getError } from './util'

export const FormRadioInput = ({
  name,
  onChange,
  element,
  setElement,
  initialValue,
  width = '100%',
  options = [],
  required = false,
  ...others
}: any) => {
  const error = element && getError(name, element)

  const radioGroup = (
    <Radio.Group
      onChange={onChange}
      name={name}
    >
      {options.map((option: { value: string; text: string }) => (
        <VyneRadio
          dataTestId={`radio-${name}-${option.value}`}
          key={option.value}
          value={option.value}
        >
          {option.text}
        </VyneRadio>
      ))}
    </Radio.Group>
  )

  if (!error) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item name={name}>{radioGroup}</Form.Item>
      </div>
    )
  }

  if (error) {
    return (
      <div className='form-item-validation-container'>
        <Form.Item
          name={name}
          validateStatus='error'
          help={error.message}
        >
          {radioGroup}
        </Form.Item>
      </div>
    )
  }
}
