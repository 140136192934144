import { List } from 'antd'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { getEligibilityStatusInfo, SearchBar, StateFilters } from 'ui'
import { isSafeApiError, LogError } from 'utils'

import GlobalState from 'trellis:state/globalState'

import {
  EligibilityPatient,
  EligibilityPatients,
  GetPatientsResponse,
} from '../../../../../api/eligibility/eligibility-client'
import { GetPatients } from '../../../../../api/eligibility/eligibilityApi'
import { Errors } from '../../../../../constants/errors'
import { useGlobalContext } from '../../../../../context/GlobalContextProvider'
import { showMessage } from '../../../../../utilities/general'

interface SearchPatientListProps {
  patient: EligibilityPatient
  setHasPatient: Dispatch<SetStateAction<boolean>>
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>
  setPatientStatus: Dispatch<
    SetStateAction<{ status: string; toolTip: string }>
  >
}

const SearchPatientList: FC<SearchPatientListProps> = ({
  patient,
  setHasPatient,
  setPatient,
  setPatientStatus,
}) => {
  const { authentication } = useGlobalContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [patients, setPatients] = useState<EligibilityPatients[]>([])
  const [requestState, setRequestState] = useState<StateFilters>({
    CurrentPage: 1,
    Filters: {
      Name: '',
    },
    Key: 0,
    PageSize: 50,
    SortColumn: {
      Column: 'Name',
      Sort: 'asc',
    },
  })

  useEffect(() => {
    if (requestState.Key !== 0) getPatients()
  }, [requestState.Key])

  const getPatients = async () => {
    setLoading(true)

    await GetPatients(requestState)
      .then(({ data: response }: AxiosResponse<GetPatientsResponse>) => {
        setPatients(response.Data)
        setLoading(false)
      })
      .catch((e) => {
        if (!isSafeApiError(e, [404])) {
          LogError(e, 'Failed to get patients')
          showMessage(Errors.getPatientsError)
        }
        setLoading(false)
      })
  }

  const handleRowClick = (item: EligibilityPatients) => {
    setHasPatient(false)
    setPatientStatus(
      getEligibilityStatusInfo(
        item.Status,
        GlobalState.EligibilityStatusMapping.peek(),
      ),
    )
    setPatient({ ...patient, PatientId: item.PatientId })
  }

  return (
    <>
      <div className='search-list__header'>
        <h4 className='mb-100'>
          Search for an existing patient to get started.
        </h4>
        <SearchBar
          placeholder='Search by patient first or last name'
          state={requestState}
          setState={setRequestState}
        />
      </div>
      <List
        dataSource={patients}
        itemLayout='horizontal'
        loading={loading}
        renderItem={(item: EligibilityPatients) => (
          <List.Item onClick={() => handleRowClick(item)}>
            <List.Item.Meta
              description={format(
                new Date(item.PatientBirthdate),
                'MM/dd/yyyy',
              )}
              title={
                <div>{`${item.PatientLastName}, ${item.PatientFirstName}`}</div>
              }
            />
            <div>{item.CarrierName}</div>
          </List.Item>
        )}
      />
    </>
  )
}

export default SearchPatientList
