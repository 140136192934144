import { Form, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import React, { FC, useEffect, useState } from 'react'

import { SelectOption, spacesToProperty } from 'utils'

import '../../_formStyles.scss'

// TODO: update to handle nested form items

// NOTE: form items also accept dependencies if validation is dependent on another field
// TODO: update to handle dependencies (e.g. confirm password is dependent on password)

interface FormSelectProps {
  className?: string
  disabled?: boolean
  floatLabel?: boolean
  label?: string
  name: string
  options: SelectOption[]
  placeholder?: string
  required?: boolean
  validator?: (value: string) => void
}

const { Option } = Select

export const FormSelect: FC<FormSelectProps> = ({
  className,
  disabled = false,
  floatLabel = true,
  label,
  name,
  options,
  placeholder = null,
  required = false,
  validator,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isLabelFloating, setIsLabelFloating] = useState<boolean>(false)

  const form = Form.useFormInstance()

  useEffect(() => {
    if (!isLabelFloating && (form.getFieldValue(name) || placeholder))
      setIsLabelFloating(true)
    else if (!form.getFieldValue(name) && !placeholder)
      setIsLabelFloating(false)

    setInputValue(form.getFieldValue(name))
  }, [form.getFieldValue(name)])

  const floatLabelClasses = `
        ant-form-item__float-label 
        ${
          isLabelFloating
            ? 'ant-form-item__float-label--true'
            : 'ant-form-item__float-label--false'
        } 
    `

  const getRules = () => {
    const rules: Rule[] = []

    rules.push({
      message: `${spacesToProperty(label || name)} is required`,
      required: required,
    })

    if (validator && inputValue && !disabled)
      rules.push({ validator: (_: Rule, value: string) => validator(value) })

    return rules
  }

  const handleOnBlur = () => {
    if (floatLabel && (inputValue || placeholder)) setIsLabelFloating(true)
    else setIsLabelFloating(false)
  }

  const handleOnChange = (value: string) => {
    if (floatLabel && value) setIsLabelFloating(true)
  }

  return (
    <div className='form-item-validation-container'>
      <Form.Item
        className={`${className} ${floatLabel && floatLabelClasses}`}
        label={label}
        name={name}
        rules={getRules()}
        validateFirst={true}
      >
        <Select
          className={`${!floatLabel ? 'no-float' : ''}`}
          disabled={disabled}
          onBlur={handleOnBlur}
          onFocus={() => setIsLabelFloating(floatLabel ? true : false)}
          onChange={handleOnChange}
          placeholder={placeholder}
        >
          {options.map((option: SelectOption) => (
            <Option
              key={option.value || option.CodeValue}
              value={option.value || option.CodeValue}
            >
              {option.text || option.CodeDescription}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}
