import { ValidatorReturn } from '../hooks'

export const validateEmail = (value: string): Promise<string | void> => {
  const isValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )

  let error: string | null = null
  if (!isValid) error = 'e.g. abc@test.com'

  if (error) return Promise.reject(error)
  else return Promise.resolve()
}

export const newValidateEmail = (value: string): ValidatorReturn => {
  const isValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )

  let error: string | null = null
  if (!isValid) error = 'e.g. abc@test.com'

  return {
    isValid,
    message: error,
  }
}
