import { LeftCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { FC, useEffect, useState } from 'react'

import { SentAttachment } from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  GetRecipient,
  GetSentAttachment,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { IAuthenticatedApiModel } from '../../../utilities/api'
import { HandleSentAttachmentPrint } from '../Common/AttachmentPrint'
import SentAttachmentContent from '../SentAttachmentContent/SentAttachmentContent'

import './SentAttachmentView.scss'

import { VyneButton } from '@vynedental/design-system'

import { Loading } from '../../_siteWide/loader/dataDisplay'
import {
  formatDateFromString,
  handleBackButtonClick,
  showMessage,
} from '../../../utilities/general'

type SentAttachmentViewProps = {
  authentication: IAuthenticatedApiModel
  sentAttachmentId: string
  setSentAttachmentId: React.Dispatch<React.SetStateAction<string>>
}

const SentAttachmentView: FC<SentAttachmentViewProps> = ({
  authentication,
  sentAttachmentId,
  setSentAttachmentId,
}) => {
  const [sentLoaded, setSentLoaded] = useState<boolean>(false)
  const [sentAttachment, setSentAttachment] = useState<SentAttachment>(null)
  const [printingAttachment, setPrintingAttachment] = useState<boolean>(false)
  const [carrierId, setCarrierId] = useState<string>('')

  useEffect(() => {
    handleBackButtonClick(() => {
      setSentAttachmentId(null)
    })
  }, [])

  useEffect(() => {
    GetSentAttachment(sentAttachmentId)
      .then(async ({ data: attachmentData }) => {
        setSentAttachment(attachmentData)

        GetRecipient(attachmentData.masterId).then(
          ({ data: recipientData }) => {
            setCarrierId(recipientData.carrierId)
          },
        )
      })
      .catch((e) => {
        showMessage('Something went wrong while loading the sent attachment')
      })
      .finally(() => {
        setSentLoaded(true)
      })
  }, [])

  return sentLoaded ? (
    <Row gutter={24}>
      <Col span={16}>
        <div className='sa-sent-header'>
          <Row className='sa-sent-header--action'>
            <Col span={12}>
              <LeftCircleOutlined
                className='back-to-all float-left fs-150'
                onClick={() => setSentAttachmentId(null)}
              />
              <span className='float-left pl-050'>Back</span>
            </Col>
            <Col
              span={12}
              className='sa-sent-header--action-button'
            >
              <VyneButton
                className='ml-050'
                dataTestId='print-attachment-button'
                loading={printingAttachment}
                onClick={() =>
                  HandleSentAttachmentPrint(
                    sentAttachment.hmfId,
                    setPrintingAttachment,
                  )
                }
                type='primary'
              >
                Print
              </VyneButton>
            </Col>
          </Row>
          <Row className='sa-sent-header--info'>
            <Col span={12}>
              <p className='fs-125 sa-sent-header--name'>{`${sentAttachment.patientFirstName} ${sentAttachment.patientLastName}`}</p>
              <p className='fs-075 sa-sent-header--dob'>
                {formatDateFromString(
                  sentAttachment.patientDateOfBirth,
                  'MM/dd/yyy',
                )}
              </p>
            </Col>
            <Col
              span={12}
              className='pl-150'
            >
              <p className='fs-0875'>{sentAttachment.recipientName}</p>
              <p className='fs-0875'>
                <b>Carrier ID:</b> <span>{carrierId}</span>
              </p>
            </Col>
          </Row>
        </div>
        <SentAttachmentContent sentAttachment={sentAttachment} />
      </Col>
      <Col span={8}>
        <section className='sa-sent--service'>
          <p className='fs-100'>
            <b>Service Information</b>
          </p>
          <p>
            {sentAttachment?.priorAuthorization
              ? 'Prior Authorization'
              : 'Claim'}
          </p>
          <p>
            {!sentAttachment?.priorAuthorization &&
              `Date(s) of Service: ${formatDateFromString(
                sentAttachment?.serviceDateFrom,
                'MM/dd/yyyy',
              )} to ${formatDateFromString(
                sentAttachment?.serviceDateTo,
                'MM/dd/yyyy',
              )}`}
          </p>
        </section>
        <section className='sa-sent--carrier'>
          <p className='fs-100'>
            <b>Carrier Reference</b>
          </p>
          <p>{sentAttachment?.recipientReferenceNumber || 'N/A'}</p>
        </section>
      </Col>
    </Row>
  ) : (
    <Loading />
  )
}

export default SentAttachmentView
