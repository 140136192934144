import {
  CalendarOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { VyneButton } from '@vynedental/design-system'
import { Col, Row } from 'antd'
import { differenceInDays, endOfDay, startOfDay } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'

import { showMessage, spacesToProperty } from '../../../../../utilities/general'
import { FilterConfigType, ObjAnyPropsType } from '../../SmartTableTypes'
import SearchControl from './SearchControl'

const columnFilterControls = (
  searchInput: React.MutableRefObject<HTMLElement>,
  search: ObjAnyPropsType,
  setSearch: Dispatch<SetStateAction<ObjAnyPropsType>>,
  property: string,
  type: string,
  onFilter: (property: string, value: string) => void,
  onRemove: (property: string) => void,
  options: [] = [],
  filter: any,
  noFilter: boolean,
  openDate: boolean,
  setOpenDate: Dispatch<SetStateAction<boolean>>,
  dateRange: { dates: [Date, Date]; numberOfDays: number },
  setDateRange: Function,
  rangeType: string,
  filterLoading: boolean,
  propertyDisplay: string,
): FilterConfigType => {
  if (noFilter) return null
  else if (filter) return filter

  const handleSearch = (value: string, confirm: () => void) => {
    if (!value) showMessage(`Please provide a ${spacesToProperty(property)}`)
    else {
      updateSearch(value)
      onFilter(property, value)
      confirm()
    }
  }

  const handleClearFilter = (clearFilters: () => void, confirm: () => void) => {
    const searchCopy = { ...search }

    if (searchCopy[property]) {
      searchCopy[property] = ''
      setSearch(searchCopy)

      if (setDateRange) {
        setDateRange({
          dates: ['', ''],
          numberOfDays: 0,
        })
      }

      onRemove(property)
      clearFilters()
    }
    setOpenDate(false)
    confirm()
  }

  const setDateFilter = (
    dates: [Date, Date],
    dateStrings: [string, string],
    confirm: () => void,
  ) => {
    const searchCopy = { ...search }
    const joinedDatesStr = dateStrings.join(', ')
    const startDate = dates[0] ? new Date(dates[0]) : new Date()
    const endDate = dates[1] ? new Date(dates[1]) : new Date()
    const numberOfDays = differenceInDays(endDate, startDate)

    searchCopy[property] = joinedDatesStr
    setSearch(searchCopy)

    setDateRange({
      dates: [startOfDay(startDate), endOfDay(endDate)],
      numberOfDays,
    })

    onFilter(property, joinedDatesStr)
    setOpenDate(false)
    confirm()
  }

  const updateSearch = (value: string) => {
    const copy = { ...search }
    copy[property] = value
    setSearch(copy)
  }

  const filterConfig: FilterConfigType = {
    filterDropdown: ({ confirm, clearFilters }) => (
      <Row
        className='p-050'
        align='middle'
      >
        <Col className='mr-050'>
          <SearchControl
            options={options}
            dateRange={dateRange}
            updateSearch={updateSearch}
            search={search}
            searchInput={searchInput}
            filterLoading={filterLoading}
            openDate={openDate}
            setDateFilter={setDateFilter}
            property={property}
            rangeType={rangeType}
            type={type}
            confirm={confirm}
            handleSearch={handleSearch}
          />
        </Col>
        <Col>
          <VyneButton
            className='mr-050'
            dataTestId={`search-${property}-column-button`}
            icon={<SearchOutlined />}
            onClick={() => handleSearch(search[property], confirm)}
            size='small'
            type='primary'
          />
          <VyneButton
            dataTestId={`clear-column-filter-button`}
            onClick={() => handleClearFilter(clearFilters, confirm)}
            icon={<CloseOutlined />}
            size='small'
          />
        </Col>
      </Row>
    ),
    filterIcon: () =>
      type === 'range' ? (
        <>
          <div className='color--column--title column-header-title'>
            {spacesToProperty(propertyDisplay)}
          </div>
          <CalendarOutlined onClick={() => setOpenDate(!openDate)} />
        </>
      ) : (
        <>
          <div className='color--column--title column-header-title'>
            {spacesToProperty(propertyDisplay)}
          </div>
          <SearchOutlined />
        </>
      ),
    ...(type === 'range' && { filterDropdownOpen: openDate }),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible && type !== 'range') {
        setTimeout(() => {
          searchInput?.current.focus()
        }, 100)
      }
    },
  }

  return filterConfig
}

export default columnFilterControls
