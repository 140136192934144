import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InboxOutlined,
  InsertRowRightOutlined,
  PrinterOutlined,
  SettingOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Col, Drawer, Input, Row, Tooltip } from 'antd'
import { observer, Show, useObservable } from '@legendapp/state/react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { SelectInstance } from 'react-select'

import { Filter, StatusDisplay } from 'ui'

import { Tabs, TabsProps, VyneSwitch } from '@vynedental/design-system'

import { QuickFilterButton } from 'trellis:components/_siteWide/QuickFilterButton/QuickFilterButton'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import { Labels } from 'trellis:constants/labels'
import {
  sentClaimFilterOptions,
  sentClaimFilters,
  setQuickFilterClasses,
  unsentClaimFilterOptions,
} from 'trellis:constants/statusFilters'
import { LDFlags$ } from 'trellis:state/globalState'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { useClaimControlContext } from '../../context/claimControlContext'
import { useClaimGridContext } from '../../context/claimGridContext'
import { ClaimSettingsModal } from '../ClaimSettings/ClaimSettingsModal'
import { ColumnSelection } from '../columnSelection/columnSelection'

import './toolbar.scss'

const { Search } = Input

export const GridToolbar = observer(() => {
  const flags = LDFlags$.get()
  const { state, setState, loading, isPmg } = useClaimGridContext()
  const {
    toggleInstall,
    toggleIgnore,
    setActiveTab,
    updateClaims,
    download,
    print,
    searchTable,
    pulse,
  } = useClaimControlContext()
  const [search, setSearch] = useState(state?.Filters?.Search)
  const [initialLoad, setInitialLoad] = useState(true)

  const showClaimColumnSelector$ = useObservable<boolean>(false)
  const showClaimSettingsModal$ = useObservable<boolean>(false)

  const quickFilterOptions = ['Accepted', 'Rejected']

  const ref = useRef<SelectInstance>(null)

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!initialLoad) searchTable(search)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  const onSearchChange = (e: any) => {
    setInitialLoad(false)
    setSearch(e.target.value || '')
  }

  const viewIgnored = state.Filters.Ignored

  const handleFilterSelect = (value: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    if (!value) resetStatusFilter()
    else if (value === copy.Filters.Config['Status']) return
    else {
      copy.Filters.Config['Status'] = value
      setState(copy)
    }
  }

  const resetStatusFilter = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    delete copy.Filters.Config['Status']
    setState(copy)
  }

  const getCurrentFilterOption = (currentFilter: string) => {
    const filterOptions =
      state.ActiveTab === 'Sent'
        ? sentClaimFilterOptions
        : unsentClaimFilterOptions

    const selectedFilter = filterOptions.find(
      (option) => option.value === currentFilter,
    )

    if (selectedFilter) {
      return {
        value: selectedFilter.value,
        title: selectedFilter.title,
        label: (
          <StatusDisplay
            status={selectedFilter.title as string}
            type='icon'
            getStatusOverride={getStatusOverride}
          />
        ),
      }
    }
  }

  const tabItems: TabsProps['items'] = [
    { label: 'Unsent', key: 'Unsent', disabled: loading },
    { label: 'Sent', key: 'Sent', disabled: loading },
  ]

  const hideGridTabs = RoleHelper$.Claims.HideGridTabs.get()

  return (
    <>
      <Row
        className={`claims-toolbar ${!hideGridTabs && 'toolbar-bottom-border'}`}
        justify='end'
        align='middle'
      >
        <Show
          if={!hideGridTabs}
        >
          {
            () => (<Col style={{ width: '105px' }}>
            <Tabs
              activeKey={state.ActiveTab}
              className='claim-tabs'
              items={tabItems}
              name='claim-grid-tabs'
              onChange={(key: string) => {
                ref.current.clearValue()
                setActiveTab(key)
              }}
            />
          </Col>)
          }
        </Show>
        <div
          style={{
            marginLeft: '2.5em',
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Filter
            currentFilter={state?.Filters?.Config['Status']}
            filterByOptions={
              state.ActiveTab === 'Sent'
                ? sentClaimFilterOptions
                : unsentClaimFilterOptions
            }
            getCurrentFilterOption={getCurrentFilterOption}
            getStatusOverride={getStatusOverride}
            handleFilterSelect={handleFilterSelect}
            ref={ref}
          />
          {state.ActiveTab === 'Sent' &&
            quickFilterOptions.map((option) => (
              <QuickFilterButton
                key={`quick-filter-${option}`}
                currentStatusFilter={state?.Filters?.Config['Status']}
                filterOptions={sentClaimFilters}
                loading={loading}
                onStatusFilterClick={handleFilterSelect}
                quickFilter={option}
                setQuickFilterClasses={setQuickFilterClasses}
              />
            ))}
        </div>
        <Col className='claim-history-search'>
          <Search
            placeholder='Search'
            onChange={onSearchChange}
            value={search}
            allowClear
          />
        </Col>
        {state.Install.SerialNickname && (
          <Row className='ml-150'>
            <div className='fs-100'>Only {state.Install.SerialNickname}</div>
            <VyneSwitch
              dataTestId='install-isolate-toolbar-switch'
              className='ml-100'
              checked={state.Install.Isolate}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={toggleInstall}
            />
          </Row>
        )}
        {state.ActiveTab === 'Sent' && (
          <Col className='grid-control-icon-container'>
            <Tooltip
              title={Labels.viewHidden}
              mouseEnterDelay={0.5}
              className='mt-025'
            >
              {viewIgnored && (
                <EyeInvisibleOutlined
                  onClick={toggleIgnore}
                  className='grid-control-icon'
                />
              )}
              {!viewIgnored && (
                <EyeOutlined
                  onClick={toggleIgnore}
                  className='grid-control-icon'
                />
              )}
            </Tooltip>
          </Col>
        )}
        {state.ActiveTab === 'Sent' && (
          <Col className='grid-control-icon-container'>
            <Tooltip
              title='Archive Claim Search'
              mouseEnterDelay={0.5}
              className='mt-025'
            >
              <Link
                to='/Archive/ClaimArchive'
                target='_blank'
              >
                <InboxOutlined className='grid-control-icon' />
              </Link>
            </Tooltip>
          </Col>
        )}
        <Col className='grid-control-icon-container'>
          <Tooltip
            title={Labels.updateClaims}
            mouseEnterDelay={0.5}
          >
            <SyncOutlined
              onClick={updateClaims}
              className={`grid-control-icon ${pulse ? 'pulse' : ''}`}
            />
          </Tooltip>
        </Col>
        <Col className='grid-control-icon-container'>
          <Tooltip
            title='Print'
            mouseEnterDelay={0.5}
          >
            {!isPmg && (
              <PrinterOutlined
                onClick={print}
                className='grid-control-icon'
              />
            )}
            {isPmg && (
              <PrinterOutlined className='grid-control-icon-disabled' />
            )}
          </Tooltip>
        </Col>
        <Col className='grid-control-icon-container'>
          <Tooltip
            title='Download'
            mouseEnterDelay={0.5}
          >
            <DownloadOutlined
              onClick={download}
              className='grid-control-icon'
            />
          </Tooltip>
        </Col>
        <Col className='grid-control-icon-container'>
          <Tooltip
            title='Settings'
            mouseEnterDelay={0.5}
          >
            {flags.createAClaim ? (
              <SettingOutlined 
                className='grid-control-icon' 
                onClick={() => showClaimSettingsModal$.set(true)}
              />
            ) : (
              <a
                href='/Rlo/Settings'
                target='_blank'
              >
                <SettingOutlined className='grid-control-icon' />
              </a>
            )}
          </Tooltip>
        </Col>
        <Col className='grid-control-icon-container'>
          <Tooltip
            title='Columns'
            mouseEnterDelay={0.5}
          >
            <InsertRowRightOutlined
              onClick={() => showClaimColumnSelector$.set(true)}
              className='grid-control-icon'
            />
          </Tooltip>
          <Drawer
            title='Select | Reorder Columns'
            placement='right'
            width={300}
            onClose={() => showClaimColumnSelector$.set(false)}
            open={showClaimColumnSelector$.get()}
          >
            <ColumnSelection
              showClaimColumnSelector$={showClaimColumnSelector$}
            />
          </Drawer>
        </Col>
      </Row>
      <ClaimSettingsModal showClaimSettingsModal$={showClaimSettingsModal$} />
    </>
  )
})
