import { IdcardOutlined } from '@ant-design/icons'
import { observer, useObservable } from '@legendapp/state/react'
import { format } from 'date-fns'
import { Tabs, TabsProps } from '@vynedental/design-system'
import { forwardRef, useEffect, useState } from 'react'

import { AlertBanner, StatusIcon } from 'ui'
import { LogError } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { PatientEligibilityForm } from '../../../api/eligibility/eligibility-client'
import { GetStructuredView } from '../../../api/eligibility/eligibilityApi'
import EligibilityResponseInsuranceUpdate from './components/eligibilityResponseInsuranceUpdate'
import NetworkToggle, { IN_NETWORK } from './components/networkToggle'
import { usePatientEligibilityDetailContext } from './context/PatientEligibilityDetailContext'
import StructuredView from './StructuredView/StructuredView'
import {
  EligibilityResponseStateType,
  OnederfulStructuredResponse,
} from './utilities/eligibilityTyping'
import { usePendoTrackEvent } from 'trellis:utilities/hooks/usePendoTrackEvent'


export interface EligibilityResponseProps {
  carrierFax?: string
  carrierId?: string
  carrierName?: string
  carrierPhone?: string
  carrierWebsite?: string
  patientBirthdate?: string
  patientName?: string
  patientStatus?: string
  verificationResponse: string
  patientResponse?: PatientEligibilityForm
  historyId?: number
  essentialsViewEnabled?: boolean
  unableToSendForm?: boolean
}

export const handleStructuredViewTabVisibility = (
  hasStructuredViewFlag: boolean,
  isStructuredViewEnabled: boolean,
  patientStatus: string,
): boolean => {
  if (
    isStructuredViewEnabled &&
    hasStructuredViewFlag &&
    patientStatus.toLocaleLowerCase() === 'eligible'
  )
    return true
  else return false
}

export const EligibilityResponse = observer(forwardRef<
  HTMLElement,
  { response: EligibilityResponseProps }
>(function EligibilityResponse({ response }, ref) {
  const {
    carrierFax,
    carrierId,
    carrierName,
    carrierPhone,
    carrierWebsite,
    patientBirthdate,
    patientName,
    patientStatus,
    verificationResponse,
    historyId,
    essentialsViewEnabled,
    unableToSendForm,
  } = response

  const carrierResponseStr = 'Carrier Response'
  const essentialsViewStr = 'Essentials View'

  const [activeTab, setActiveTab] = useState<string>(carrierResponseStr)
  const [websiteDisplay, setWebsiteDisplay] = useState<string>(carrierWebsite)

  const { patientEligibilityResponse } = usePatientEligibilityDetailContext()

  const flags = LDFlags$.get()

  const EligibilityResponseState = useObservable<EligibilityResponseStateType>({
    currentNetwork: IN_NETWORK,
    structuredOnederfulResponse: null,
    structuredView: null,
    currentBenefits: null,
  })

  useEffect(() => {
    // Send track event to Pendo when Carrier Response is viewed
    usePendoTrackEvent('CarrierResponseViewed', 'Eligibility', {
      id: historyId.toString(),
      timestamp: new Date().toISOString(),
    })
  }, [])

  useEffect(() => {
    if (carrierWebsite?.includes('https://www.'))
      setWebsiteDisplay(carrierWebsite.split('https://www.')[1])
    else if (carrierWebsite?.includes('https://'))
      setWebsiteDisplay(carrierWebsite.split('https://')[1])
  }, [carrierWebsite])
  
  const getStructuredView = async () => {
    try {
      const { data } = await GetStructuredView(historyId)
      const structuredResponse: OnederfulStructuredResponse = JSON.parse(
        data.StructuredView,
      )

      EligibilityResponseState.set({
        currentNetwork: IN_NETWORK,
        structuredOnederfulResponse: data,
        structuredView: structuredResponse,
        currentBenefits: structuredResponse?.benefits[0],
      })
    } catch (e) {
      LogError(e, 'Error while retrieving eligibility Structured View')
    }
  }

  const handleTabChange = (tabKey: string) => {
    switch (tabKey) {
      case carrierResponseStr:
        break
      case essentialsViewStr:
        if (!EligibilityResponseState?.structuredOnederfulResponse?.get())
          getStructuredView()
        break
      default:
        break
    }
    setActiveTab(tabKey)
  }

  const tabItems: TabsProps['items'] = [
    {
      key: carrierResponseStr,
      label: carrierResponseStr
    },
  ]

  if (handleStructuredViewTabVisibility(
    flags.structuredView,
    essentialsViewEnabled,
    patientStatus,
  )) {
    tabItems.push({
      key: essentialsViewStr,
      label: <div data-testid='structured-view-tab'>{essentialsViewStr}</div>
    })
  }

  return (
    <section
      className='eligibility-response'
      data-testid='eligibility-response'
      ref={ref}
    >
      <div className='eligibility-response__header'>
        <div className='flex justify-between items-center mb-100'>
          {patientName && (
            <article className='eligibility-response__patient-info'>
              <section className='eligibility-response__patient-name-status'>
                <h3>{patientName}</h3>
                {patientStatus && (
                  <section
                    className={`status-container status-container--badge`}
                  >
                    <StatusIcon
                      status={patientStatus}
                      type='badge'
                    />
                    <p className='ml-050'>
                      {patientStatus === 'In Queue' ? 'Ready' : patientStatus}
                    </p>
                  </section>
                )}
              </section>
              {patientBirthdate && (
                <p>
                  DOB:{' '}
                  {format(new Date(Date.parse(patientBirthdate)), 'MM/dd/yyy')}
                </p>
              )}
            </article>
          )}
          {carrierId && (
            <article className='eligibility-response__carrier-info'>
              <div>
                <IdcardOutlined />
                {carrierName && (
                  <p>
                    <strong>{carrierName}</strong>
                  </p>
                )}
                {carrierId && (
                  <p>
                    <strong>Carrier ID: </strong>
                    <span>{carrierId}</span>
                  </p>
                )}
              </div>
              <div>
                {carrierFax && (
                  <p>
                    <strong>Fax: </strong>
                    <span>{carrierFax}</span>
                  </p>
                )}
                {carrierPhone && (
                  <p>
                    <strong>Phone: </strong>
                    <span>{carrierPhone}</span>
                  </p>
                )}
                {carrierWebsite && (
                  <p>
                    <strong>Website: </strong>
                    <a
                      href={carrierWebsite}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {websiteDisplay}
                    </a>
                  </p>
                )}
              </div>
            </article>
          )}
        </div>
        {unableToSendForm && (
          <AlertBanner
            message='Although the automated Patient Update Form is active, the patient specified a preference to not receive text and/or email communications.'
            title="Oops! We Couldn't Send the Patient Update Form"
            type='error'
          />
        )}
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={activeTab}
          items={tabItems}
          name='patient-verification-response-tabs'
          onChange={(key: string) => handleTabChange(key)}
          tabBarExtraContent={
            flags.structuredView &&
            essentialsViewEnabled &&
            activeTab === essentialsViewStr && (
              <NetworkToggle state={EligibilityResponseState} />
            )
          }
        />
      </div>
      <div className='eligibility-response__tab-content-container'>
        {activeTab === carrierResponseStr && (
          <>
            {patientEligibilityResponse && (
              <EligibilityResponseInsuranceUpdate
                patientResponse={patientEligibilityResponse}
              />
            )}
            <div
              className='eligibility-response__content--original'
              dangerouslySetInnerHTML={{
                __html: verificationResponse || 'No Results Found',
              }}
            />
          </>
        )}
        {activeTab === essentialsViewStr && (
          <StructuredView state={EligibilityResponseState} />
        )}
      </div>
    </section>
  )
}))

export default EligibilityResponse
