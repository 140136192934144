import DOMPurify from 'dompurify'
import { FC, useEffect, useRef } from 'react'

type HtmlChunkProps = { data: string }

const HtmlChunk:FC<HtmlChunkProps> = ({ data }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const shadowRoot = containerRef.current.attachShadow({ mode: 'open' })
      const sanitizedHtml = DOMPurify.sanitize(data)
      shadowRoot.innerHTML = sanitizedHtml
    }
  }, [data])

  return <div ref={containerRef}></div>
}

export default HtmlChunk
