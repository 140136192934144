import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { VyneButton } from '@vynedental/design-system'
import { Modal } from 'antd'

import styles from './_downloads.module.scss'

import DownloadIcon from 'trellis:assets/cloud-download-icon.svg?react'

interface DownloadItemConfirmationModalProps {
  confirmationPromptMessage: string
  confirmationPromptTitle: string
  isOpen: Observable<boolean>
  title: string
  url: string
}

export const DownloadItemConfirmationModal = observer(
  ({
    confirmationPromptMessage,
    confirmationPromptTitle,
    isOpen,
    title,
    url,
  }: DownloadItemConfirmationModalProps) => {
    return (
      <Modal
        closable
        destroyOnClose={true}
        footer={[
          <VyneButton
            dataTestId='cancel-download-button'
            key='cancel'
            onClick={() => isOpen.set(false)}
          >
            Cancel
          </VyneButton>,
          <VyneButton
            dataTestId='download-button'
            href={url}
            icon={
              <span
                className={`anticon ${styles['download-item__icons--vertical-align']}`}
              >
                <DownloadIcon />
              </span>
            }
            key='download'
            type='primary'
          >
            Download
          </VyneButton>,
        ]}
        onCancel={() => isOpen.set(false)}
        open={isOpen.get()}
        title={`Download ${title}`}
      >
        <div className='text-center'>
          <h6 className='fs-100 mb-050'>{confirmationPromptTitle}</h6>
          <p className='fs-0875'>{confirmationPromptMessage}</p>
        </div>
      </Modal>
    )
  },
)
