import { VyneButton } from '@vynedental/design-system'
import { Row } from 'antd'
import { FC, FormEvent } from 'react'

import { IAttachmentImage } from '../../../attachment/attachment.interface'
import { MaxAttachmentSizeError } from '../../constants'

type SaveImageProps = {
  overMaxSize: boolean
  storeAttachmentImageLocal?: (
    attachmentImage: IAttachmentImage,
    showDetailsView: boolean,
  ) => void
  saveData: () => void
}

const SaveImage: FC<SaveImageProps> = ({
  overMaxSize,
  storeAttachmentImageLocal,
  saveData,
}) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    saveData()
  }

  return (
    <Row
      justify='end'
      style={{ flexWrap: 'nowrap' }}
    >
      <VyneButton
        className='mr-050'
        dataTestId='cancel-image-button'
        onClick={() => storeAttachmentImageLocal(null, false)}
        size='large'
      >
        Cancel
      </VyneButton>
      {overMaxSize == true && (
        <span className='error'>{MaxAttachmentSizeError}</span>
      )}
      {overMaxSize == false && (
        <VyneButton
          className='ml-100'
          dataTestId='save-image-button'
          onClick={handleSubmit}
          size='large'
          type='primary'
        >
          Save
        </VyneButton>
      )}
    </Row>
  )
}

export default SaveImage
