import { CreditCardOutlined } from '@ant-design/icons'
import { VyneButton } from '@vynedental/design-system'
import { Col, Row } from 'antd'

import { CreditCardTypes } from '../../../../constants/general'
import { Labels } from '../../../../constants/labels'
import { AddressControls } from '../../address/addressControls'
import { usePaymentContext } from '../context/paymentContext'
import { PaymentControls } from '../paymentControls'

import './BillingContent.scss'

export const BillingContent = () => {
  const {
    saving,
    loading,
    isNew,
    payment,
    setPayment,
    handleLogout,
    handleSavePayment,
  } = usePaymentContext()

  const paymentMethod = isNew ? (
    `${Labels.addPaymentMessagePart1} ${Labels.billingPhone}`
  ) : (
    <span className='page-section__description'>
      <CreditCardOutlined className='pr-050' />
      <b>{CreditCardTypes[payment?.cardType?.toUpperCase()]}</b>
      <span className='pl-025 pr-025'>ending in</span>
      <b>{payment?.lastFour}</b>
      <span className='pl-100 pr-025'>Expires</span>
      <span>{payment?.expDate}</span>
    </span>
  )
  const termsConditions = (
    <b>
      <a
        href='https://vynedental.com/terms/'
        target='_blank'
        rel='noopener noreferrer'
      >
        Terms And Conditions
      </a>
    </b>
  )

  return (
    <div className='billing-content-wrapper'>
      <div className='form-detail-section'>
        <section className='page-section'>
          <div className='flex flex-row-wrap justify-between mb-050'>
            <h3 className='page-section__title'>Payment Method</h3>
            <p className='sub-section-title'>{paymentMethod}</p>
          </div>
          <PaymentControls />
        </section>
        <section className='page-section'>
          <div className='page-section__title'>Billing Address</div>
          <AddressControls
            prefix='billing'
            state={payment}
            setState={setPayment}
            section='payment'
          />
        </section>
        <div className='page-section'>
          <p>
            By clicking "Submit" you agree to our online {termsConditions} and
            authorize use of this card on a recurring basis for payments in
            accordance with your subscription terms and fees.
          </p>
        </div>
        <div className='flex justify-end align-center'>
          {window.location.pathname === '/Account/LoginPaymentInfo' && (
            <VyneButton
              className='mt-100 mr-100'
              dataTestId='logout-button'
              loading={loading}
              onClick={handleLogout}
              size='large'
            >
              Log Out
            </VyneButton>
          )}
          <VyneButton
            className='mt-100'
            dataTestId='save-payment-button'
            loading={saving}
            onClick={handleSavePayment}
            size='large'
            type='primary'
          >
            Submit
          </VyneButton>
        </div>
      </div>
    </div>
  )
}

export const NoBillingContent = () => {
  return (
    <Row>
      <Col
        span={12}
        className='form-detail-section'
      >
        <div className='page-section'>
          <div className='page-section__title'>
            Payment Info (ACH or PayPal)
          </div>
          <div className='page-section__description'>
            {Labels.billingInfoNoCard} <b>{Labels.billing}</b> at{' '}
            <b>{Labels.billingPhone}</b>
          </div>
        </div>
      </Col>
    </Row>
  )
}
