import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { VyneButton } from '@vynedental/design-system'
import React, { FC, useState } from 'react'

import { IDialogConfirm } from '../attachment.interface'

import '../attachment.scss'

const DialogConfirm: FC<IDialogConfirm> = ({
  dialogTitle,
  dialogText,
  dialogDenyText,
  dialogConfirmText,
  dialogOnClickYes,
  toggleDialog,
}) => {
  const [onClickYes, setOnClickYes] = useState<(props: [...any]) => any>(
    () => dialogOnClickYes,
  )

  return (
    <Dialog
      title={dialogTitle}
      onClose={toggleDialog}
    >
      <p style={{ margin: '25px', textAlign: 'center', fontSize: '12px' }}>
        {dialogText}
      </p>
      <DialogActionsBar>
        <VyneButton
          className='mr-050 button button-rg button-secondary'
          dataTestId='dialog-deny-button'
          onClick={toggleDialog}
        >
          {dialogDenyText}
        </VyneButton>
        <VyneButton
          className='button button-rg button-primary'
          dataTestId='dialog-confirm-button'
          onClick={() => {
            toggleDialog()
            setOnClickYes(onClickYes)
          }}
        >
          {dialogConfirmText}
        </VyneButton>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogConfirm
