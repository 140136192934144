export class ExportTableAsCSV {
  table: HTMLDivElement
  rows: HTMLTableRowElement[]

  constructor(table: HTMLDivElement, includeHeaders = true) {
    this.table = table
    this.rows = Array.from(table.querySelectorAll('tr'))

    if (!includeHeaders && this.rows[0].querySelectorAll('th').length)
      this.rows.shift()
  }

  _findLongestRowLength() {
    return this.rows.reduce(
      (length, row) =>
        row.childElementCount > length ? row.childElementCount : length,
      0,
    )
  }

  convertToCSV() {
    const lines: string[] = []
    const totalColumns = this._findLongestRowLength()

    this.rows.forEach((row: HTMLTableRowElement) => {
      let line = ''

      for (let i = 0; i < totalColumns; i++) {
        if (row.children[i] !== undefined) {
          line += ExportTableAsCSV.parseCell(
            row.children[i] as HTMLTableCellElement,
          )
        }

        line += i !== totalColumns - 1 ? ',' : ''
      }

      lines.push(line)
    })

    return lines.join('\n')
  }

  static parseCell(tableCell: HTMLTableCellElement) {
    let parsedValue: string | undefined | null = tableCell.textContent

    // replace double quotes with two double quotes
    parsedValue = tableCell.textContent?.replace(/"/g, '""')

    // if value contains double quote, comma, or new line then enclose in double quotes
    parsedValue = /[",\n]/.test(parsedValue ? parsedValue : '')
      ? `"${parsedValue}"`
      : parsedValue

    return parsedValue
  }
}
