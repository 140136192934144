import { FC } from 'react'

import { StatusFilterOption } from 'trellis:constants/statusFilters'

type QuickFilterButtonProps = {
  currentStatusFilter: string
  filterOptions: StatusFilterOption[]
  loading: boolean
  onStatusFilterClick: (
    filter: string,
    title?: string,
    label?: JSX.Element,
  ) => void
  quickFilter: string
  setQuickFilterClasses: (filter: string, currentStatusFilter: string) => string
}

export const QuickFilterButton: FC<QuickFilterButtonProps> = ({
  currentStatusFilter,
  filterOptions,
  loading,
  onStatusFilterClick,
  quickFilter,
  setQuickFilterClasses,
}) => {
  const filterOption = filterOptions.find(
    (filter) => filter.Value === quickFilter,
  )

  return (
    <button
      className={setQuickFilterClasses(quickFilter, currentStatusFilter)}
      disabled={loading}
      key={quickFilter}
      onClick={() => onStatusFilterClick(quickFilter)}
    >
      {filterOption?.Icon}
      {filterOption?.Label}
    </button>
  )
}
