import { VyneRadio } from '@vynedental/design-system'
import { RadioChangeEvent } from 'antd'
import { FC } from 'react'

import styles from './FormRadioInput.module.scss'

interface FormRadioInputProps {
  checked: boolean
  label: string
  name: string
  onChange: (e: RadioChangeEvent) => void
  value: string
}

export const FormRadioInput: FC<FormRadioInputProps> = (props) => {
  return (
    <VyneRadio
      {...props}
      dataTestId={`form-radio-button-${props.value}`}
    >
      <p className={styles['form-control-group__radio-label']}>{props.label}</p>
    </VyneRadio>
  )
}
