import type { MenuProps } from 'antd'

import { Col, Dropdown, Row, Tooltip } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { ImageOrientationType } from '../../attachment.interface'

import './xRayOrientation.scss'

import { DownOutlined, InfoCircleFilled } from '@ant-design/icons'

type xRayOrientationProps = {
  setOrientation: Dispatch<SetStateAction<ImageOrientationType>>
  orientation: ImageOrientationType
}

const XRayOrientation: FC<xRayOrientationProps> = ({
  setOrientation,
  orientation,
}) => {
  const [orientationText, setOrientationText] = useState<string>(null)

  useEffect(() => {
    setOrientationText(items.find((item) => item.key === orientation)['label'])
  }, [])

  const items: MenuProps['items'] = [
    {
      key: 'LEFT',
      label: 'Left',
    },
    {
      key: 'RIGHT',
      label: 'Right',
    },
  ]

  const handleOrientation = (key: string) => {
    setOrientationText(items.find((item) => item.key === key)['label'])
    const imageOrientation =
      key === 'RIGHT'
        ? ImageOrientationType.LeftIsRight
        : ImageOrientationType.LeftIsLeft
    setOrientation(imageOrientation)
    localStorage.setItem('localStorageImageOrientation', imageOrientation)
  }

  return (
    <Row className='mb-150'>
      <Col
        span={24}
        className='xray-orientation'
      >
        <div className='xray-orientation__title mb-050'>
          <span>Orientation</span>
          <Tooltip
            title="Right = Left side of the x-ray is the patient's right side (most common)
            Left = Left side of the x-ray is the patient's left side"
          >
            <InfoCircleFilled />
          </Tooltip>
        </div>
      </Col>
      <Col
        span={24}
        className='xray-orientation'
      >
        <Dropdown
          menu={{
            className: 'image-type-container image-type__dropdown__submenu',
            items,
            onClick: ({ key }) => {
              handleOrientation(key)
            },
          }}
          overlayStyle={{ borderRadius: 0 }}
        >
          <a className={`xray-orientation__dropdown`}>
            <span>{orientationText} &nbsp;</span>
            <DownOutlined />
          </a>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default XRayOrientation
