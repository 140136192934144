import { Buffer } from 'buffer'

import * as PNotifyBootstrap4 from '@pnotify/bootstrap4'
import { defaultModules, error } from '@pnotify/core'
import * as PNotifyFontAwesome5 from '@pnotify/font-awesome5'
import * as PNotifyFontAwesome5Fix from '@pnotify/font-awesome5-fix'
import { message } from 'antd'
import { format, isValid } from 'date-fns'

import { LogError } from 'utils'

import { TrellisJWT } from 'trellis:state/globalState'

import trellisConfiguration from './config'

defaultModules.set(PNotifyFontAwesome5Fix, {})
defaultModules.set(PNotifyFontAwesome5, {})
defaultModules.set(PNotifyBootstrap4, {})

export const getVerificationToken = () => {
  const verificationToken: any = document.querySelector(
    '[name="__RequestVerificationToken"]',
  )
  return verificationToken && verificationToken.value
}

export const dateOnlyFormat = 'MM/dd/yyyy'
export const dateTimeFormat = 'MM/dd/yyyy h:mm a'

/** formats date to MM/dd/yyyy */
export const formatInputDate = (value: string): string => {
  if (value) {
    const result = formatDateString(value, dateOnlyFormat)
    return result == emptyDateDefault ? null : result
  }
  return null
}

/** formats a date for the grid or returns a default if it's empty (N/A) */
export const formatColumnDate = (
  value: string,
  showTime: boolean = false,
): string => {
  return formatDateString(value, showTime ? dateTimeFormat : dateOnlyFormat)
}

export const formatDownloadDateTime = (value: string) => {
  return value ? format(new Date(value), dateTimeFormat) : ''
}

export const updateDateForSafari = (value: string): string => {
  // safari does not support decimals in seconds like "11/11/2024 02:14:04.340 PM"
  // needs to support formats like "11/11/2024 02:14:04.340 PM" and "2024-11-07T21:53:24.22Z"
  if (value?.includes('.')) {
    // split on the decimal
    const splitDate = value.split('.')
    // handle the case with AM/PM
    if (splitDate[1].includes(' ')) {
      const newAmPm = ` ${splitDate[1].split(' ')[1]}`
      value = `${splitDate[0]}${newAmPm}`
    } else if (splitDate[1].includes('Z')) {
      value = `${splitDate[0]}Z`
    } else {
      value = splitDate[0]
    }
  }
  return value
}

const emptyDateDefault = 'N/A'
/** formats a date with the given format, returns a default (N/A) if the date is invalid */
export const formatDateString = (value: string, dateFormat: string): string => {
  const newValue = updateDateForSafari(value)

  if (newValue) {
    const date = new Date(newValue)
    if (isValid(date) && date.getFullYear() != 1) {
      return format(date, dateFormat)
    }
  }
  return emptyDateDefault
}

export const redirectToLogin = () => {
  localStorage.clear()
  window.location.href = '/Account/login'
}

export const handleBackButtonClick = (handler: () => void) => {
  let hasPopStateChanged = false
  const onPopStateChange = (event: PopStateEvent) => {
    event.preventDefault()

    if (!hasPopStateChanged) {
      handler()
      window.history.pushState(null, null, window.location.pathname)
      hasPopStateChanged = true
    }
  }

  window.history.pushState(null, null, window.location.pathname)
  window.addEventListener('popstate', onPopStateChange)

  return () => window.removeEventListener('popstate', onPopStateChange)
}

export const showMessage = (
  description: string,
  type: string = 'error',
  duration?: number,
) => {
  message.destroy()
  switch (type) {
    case 'success':
      message.success(description, duration)
      break
    case 'error':
      message.error(description, duration)
      break
    case 'info':
      message.info(description, duration)
      break
    case 'warning':
      message.warning(description, duration)
      break
  }
}

export const ErrorPNotify = (message: string) => {
  try {
    error({
      text: message,
      title: 'Error',
    })
  } catch (error) {
    LogError(error)
  }
}

export const validNpi = (npi: string): any => {
  const result: any = {
    format: true,
    valid: true,
    value: npi || '',
  }
  if (!npi || npi === '') return result
  result.format = new RegExp('^[1][0-9]{9}$').test(npi)

  if (!result.format) return result

  let sum = 0
  for (let i = npi.length - 2; i >= 0; i--) {
    if (i % 2 === 0) {
      const double = parseInt(npi[i]) * 2
      const doubleString = double.toString()
      sum +=
        doubleString.length > 1
          ? parseInt(doubleString[0]) + parseInt(doubleString[1])
          : parseInt(doubleString)
    } else {
      sum += parseInt(npi[i])
    }
  }

  sum += 24

  const unitsDigit = parseInt(sum.toString()[sum.toString().length - 1])
  const checkDigit = unitsDigit > 0 ? 10 - unitsDigit : unitsDigit
  result.valid = checkDigit === parseInt(npi[npi.length - 1])
  return result
}

export const FormatDynamicStringResource = (
  stringResource: string,
  ...variables: any
) => {
  let dynamicString = stringResource

  variables.map((str: string, i: number) => {
    const dynamicArray = dynamicString.split(`{${i}}`)

    if (dynamicArray.length === 1) {
      return
    } else if (dynamicArray.length === 2) {
      dynamicString = `${dynamicArray[0]}${str}${dynamicArray[1]}`
    } else {
      let newStr = ''

      dynamicArray.map((resourceStr: string, j: number) => {
        if (j === dynamicArray.length - 1) {
          newStr += resourceStr
        } else {
          newStr += `${resourceStr}${variables[i]}`
        }
      })

      dynamicString = newStr
    }
  })

  return dynamicString
}

export const formatProviderName = (element: any) => {
  // NOTE: trellis and vds data is different casing
  const firstName = element.providerFirstName
    ? element.providerFirstName
    : element.ProviderFirstName
  const lastName = element.providerLastName
    ? element.providerLastName
    : element.ProviderLastName
  return firstName && lastName && `${lastName}, ${firstName}`
}

export const addCommasToNumber = (value: string) => {
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const removeCommasInNumber = (value: string) => {
  return value && value.toString().replace(/,/g, '')
}

export const getPayDateOptions = () => {
  const days = []
  for (let i = 1; i <= 31; i++) {
    const month = i < 10 ? `0${i}` : `${i}`
    days.push({ value: month, text: month })
  }

  return days
}

export const ParseDateId = (dateId: any) => {
  if (dateId == undefined) {
    return ''
  }
  // Bad date id
  if (dateId.toString().length != 8) {
    return '00/00/0000'
  }
  const year = dateId.toString().substring(0, 4)
  const month = dateId.toString().substring(4, 6)
  const day = dateId.toString().substring(6, 8)
  return `${month}/${day}/${year}`
}

export const makeDateId = (date: any) => {
  const dateValues = date.split('/')
  const month = dateValues[0]
  const day = dateValues[1]
  const year = dateValues[2]
  return parseInt(`${year}${month}${day}`)
}

export const hasProperties = (obj: any) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return true
    }
  }
  return false
}

export const getProviderInformation = (data: any) => {
  return {
    hasLastName: data.providerLastName && data.providerLastName !== '',
    hasFirstName: data.providerFirstName && data.providerFirstName !== '',
    hasNpi: data.providerNPI && data.providerNPI !== '',
    hasTaxonomy: data.taxonomyCode && data.taxonomyCode !== '',
  }
}

export const buildQueryString = (queries: any = null) => {
  if (!queries) return ''
  const queryStrings: string[] = []
  let queryString = ''

  if (queries && Object.keys(queries).length > 0) {
    Object.keys(queries).forEach((query) => {
      if (typeof queries[query] == 'object') {
        if (Object.keys(queries[query]).length > 0) {
          Object.keys(queries[query]).forEach((key) => {
            queryStrings.push(`${query}[${key}]=${queries[query][key]}`)
          })
        }
      } else {
        queryStrings.push(`${query}=${queries[query]}`)
      }
    })

    queryString = '?' + queryStrings.join('&')
  }

  return queryString
}

export const printHtmlContent = (html: string) => {
  const win = window.open()
  win.document.body.innerHTML = html
  win.document.close()
  win.focus()
  setTimeout(function () {
    win.print()
  }, 100)
}

export const spacesToProperty = (property: string) => {
  let propertyName = property
  if (Array.isArray(property)) propertyName = property[1]
  if (property.includes('_')) propertyName = propertyName.replace('_', ' ')
  return capitalize(propertyName.replace(/([a-z])([A-Z])/g, '$1 $2'))
}

export const capitalize = (property: string) => {
  return property.charAt(0).toUpperCase() + property.slice(1)
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function arrayMove(array: any, fromIndex: number, toIndex: number) {
  array = [...array]
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex
    const item = array.splice(fromIndex, 1)[0]
    array.splice(endIndex, 0, item)
  }

  return array
}

export const extractTypeProperties = <
  WantedProperties,
  PropertyValues extends WantedProperties,
>(
  properties: WantedProperties,
  values: PropertyValues,
) => {
  const result = { ...properties }

  ;(Object.keys(properties) as Array<keyof WantedProperties>).forEach(
    (property) => {
      if (values.hasOwnProperty(property)) result[property] = values[property]
    },
  )

  return result
}

export const decodeJWT = (token: string): TrellisJWT => {
  const base64Payload = token.split('.')[1]
  const payloadBuffer = Buffer.from(base64Payload, 'base64')
  return JSON.parse(payloadBuffer.toString())
}

export const stringContainsNonAsciiCharacters = (value: string) => {
  // Check for non-ascii characters from space 32 to tilde 126, including tabs and newlines
  const ascii = /^[ -~\t\n\r]+$/

  // Includes the use of '<' and '>' characters
  return (
    !ascii.test(value) || value.indexOf('<') !== -1 || value.indexOf('>') !== -1
  )
}

export const formatDateFromString = (
  value: string,
  dateFormat: string,
  warningMessage?: string,
) => {
  try {
    if (value) {
      const dt = new Date(value)
      // consider timezone so midnight doesnt roll us back a day
      const dtDateOnly = new Date(
        dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
      )
      return format(dtDateOnly, dateFormat)
    }
  } catch (error) {
    warningMessage ?? message.warning(warningMessage)
  }
  return ''
}

export const dateFromString = (
  value: string,
  warningMessage?: string,
): Date => {
  try {
    if (value) {
      const dt = new Date(value)
      // consider timezone so midnight doesnt roll us back a day
      return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
    }
  } catch (error) {
    warningMessage ?? message.warning(warningMessage)
  }
  return null
}

export const asyncForEach = async (array: any, callback: any) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const getSearchParamsLowerCase = (
  searchParams: URLSearchParams,
): URLSearchParams => {
  const newParams = new URLSearchParams()
  for (const [name, value] of searchParams) {
    newParams.append(name?.toLowerCase(), value)
  }
  return newParams
}

export const getQueryParamAndDelete = (
  searchParams: URLSearchParams,
  key: string,
): string => {
  const value = searchParams.get(key)
  searchParams.delete(key)
  return value
}

export const isDiSite = (): boolean => {
  return getTheme() == 'di'
}

export const isRpSite = (): boolean => {
  return getTheme() == 'rpractice'
}

export const isTrellisSite = (): boolean => {
  return getTheme() == 'trellis'
}

const isLocalhost =
  window?.location?.host?.toLowerCase()?.includes('localhost') ?? false

const getTheme = (): string => {
  if (
    window.location.host?.toLowerCase().includes('di.') ||
    (isLocalhost && trellisConfiguration.localhost_di)
  ) {
    return 'di'
  } else if (
    window.location.host?.toLowerCase().includes('rpractice') ||
    (isLocalhost && trellisConfiguration.localhost_rp)
  ) {
    return 'rpractice'
  }

  return 'trellis'
}
