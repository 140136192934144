import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { VyneButton } from '@vynedental/design-system'
import { Form, message, Modal } from 'antd'
import { FC } from 'react'

import { FormInput } from 'ui'

import { PearlCredentials } from 'trellis:api/pearl/pearl-provision-client'
import GlobalState from 'trellis:state/globalState'

import styles from '../../_downloads.module.scss'
import ContactSupport from '../../../../components/layouts/PostAuthLayout/components/Header/modals/ContactSupport'
import { usePearlDownload } from '../hooks/usePearlDownload'

interface ImageSyncCredentialsModalProps {
  isOpen: Observable<boolean>
  onSuccess?: () => void
  onClose?: () => void
  onError?: () => void
}

export const ImageSyncCredentialsModal: FC<ImageSyncCredentialsModalProps> =
  observer(({ isOpen, onSuccess, onError, onClose }) => {
    const { handleSupportCredentials, isLoading } = usePearlDownload()
    const [form] = Form.useForm()
    const isContactSupportOpen = useObservable(false)
    const customerId = GlobalState.Auth.CustomerIdNumber.get()

    const handleSubmit = async (values: PearlCredentials) => {
      try {
        const success = await handleSupportCredentials(customerId, values)
        if (success) {
          form.resetFields()
          isOpen.set(false)
          onSuccess?.()
        } else {
          onError?.()
        }
      } catch (error) {
        await message.error('An error occurred while saving credentials')
        onError?.()
      }
    }
    const handleClose = () => {
      form.resetFields()
      onClose?.()
    }

    return (
      <>
        <Modal
          title='Provisioning Required'
          open={isOpen.get()}
          onCancel={handleClose}
          footer={[
            <div
              key='footer-content'
              className='flex-row items-center justify-between w-full'
            >
              <span style={{ color: '#868686', fontSize: '14px' }}>
                SUPPORT USE ONLY
              </span>
              <div className='flex-row gap-100'>
                <VyneButton
                  dataTestId='close-image-sync-button'
                  key='close'
                  onClick={handleClose}
                >
                  Close
                </VyneButton>
                <VyneButton
                  dataTestId='submit-image-sync-button'
                  key='submit'
                  loading={isLoading.get()}
                  onClick={() => {
                    void form
                      .validateFields()
                      .then((values) =>
                        handleSubmit(values as PearlCredentials),
                      )
                      .catch(() => {
                        // Form validation failed - no need to log
                      })
                  }}
                  type='primary'
                >
                  Submit
                </VyneButton>
              </div>
            </div>,
          ]}
        >
          <div className='flex-column gap-100'>
            <h6 className={styles['download-item__title']}>
              Pearl Credentials
            </h6>
            <p>
              If you&apos;re an existing Pearl customer or if your download was
              unsuccessful, please{' '}
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  isContactSupportOpen.set(true)
                }}
              >
                Contact Us
              </a>{' '}
              for assistance. A support representative will help you enter the
              necessary details in the fields below
            </p>

            <Form
              form={form}
              requiredMark={false}
              colon={false}
            >
              <FormInput
                label='Organization ID'
                name='organizationId'
                required
              />
              <FormInput
                label='Pearl Office ID'
                name='officeId'
                required
              />
              <FormInput
                label='Client ID'
                name='clientId'
                required
              />
              <FormInput
                label='Secret Key'
                name='clientSecret'
                required
              />
            </Form>
          </div>
        </Modal>

        <ContactSupport
          isVisible={isContactSupportOpen.get()}
          setIsVisible={(value) => isContactSupportOpen.set(value)}
        />
      </>
    )
  })
