import {
  VyneDesignSystemProvider,
  VyneUserThemes,
} from '@vynedental/design-system'
import { FC, ReactNode, useEffect } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'

import { ClearSentryUser } from 'utils'

import { appTheme$ } from 'trellis:state/globalState'
import { setTheme } from 'trellis:theme/setTheme'
import { isDiSite } from 'trellis:utilities/general'

import Footer from './Footer/Footer'

import './PreAuthLayout.scss'

const PreAuthLayout: FC = (): ReactNode => {
  const appTheme = useLoaderData() as VyneUserThemes

  useEffect(() => {
    // reset the ld scope
    ClearSentryUser()
  }, [])

  return (
    <VyneDesignSystemProvider facilityId={'vyne-shared-preauth-user'}>
      <div className={`pre-auth-layout ${appTheme}`}>
        <Outlet />
      </div>
    </VyneDesignSystemProvider>
  )
}

export const PublicLayoutWrapper: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <main className='main__wrapper'>
    <div className='main__content-wrapper'>{children}</div>
    <Footer />
  </main>
)

export const loaderPreAuthLayout = async (): Promise<VyneUserThemes> => {
  const isDI = isDiSite()

  let appTheme: VyneUserThemes
  if (isDI) appTheme = VyneUserThemes.dentalIntelligenceTheme
  else appTheme = VyneUserThemes.trellisTheme

  appTheme$.set(appTheme)
  setTheme(appTheme)

  return appTheme
}

export default PreAuthLayout
