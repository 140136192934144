import type { MenuProps } from 'antd'

import {
  DownOutlined,
  FileDoneOutlined,
  PaperClipOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { VyneButton } from '@vynedental/design-system'
import { Col, Dropdown, Row, Tag } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'

import { CarrierDto } from 'trellis:api/carrier/carrier-client'
import { CarrierListFilters } from 'trellis:pages/CarrierList'

import styles from './_ServiceFilter.module.scss'

interface ServiceFilterProps {
  filters: CarrierListFilters
  loading: boolean
  setFilters: Dispatch<SetStateAction<CarrierListFilters>>
}

const ServiceFilter: FC<ServiceFilterProps> = ({
  filters,
  loading,
  setFilters,
}) => {
  const serviceFilterItems: MenuProps['items'] = [
    {
      icon: (
        <SendOutlined className={styles['carrier-service-filter__item-icon']} />
      ),
      key: 'RtClaims',
      label: 'Real-time Claims',
    },
    {
      icon: (
        <FileDoneOutlined
          className={styles['carrier-service-filter__item-icon']}
        />
      ),
      key: 'Eligibility',
      label: 'Eligibility',
    },
    {
      icon: (
        <PaperClipOutlined
          className={styles['carrier-service-filter__item-icon']}
        />
      ),
      key: 'NeaMasterId',
      label: 'Attachments',
    },
    {
      icon: (
        <svg
          className={styles['carrier-service-filter__item-icon--era']}
          viewBox='0 0 40 40'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill='var(--charcoal-200, #87878A)'
            d='M30.7477 10.6102L20.9812 0.748828C20.823 0.590625 20.6508 0.457031 20.4645 0.344531C20.4152 0.316406 20.366 0.288281 20.3133 0.263672C20.2816 0.246094 20.2465 0.232031 20.2148 0.217969C19.8984 0.0773437 19.5504 0 19.1953 0H7.06641C5.66719 0 4.5 1.13203 4.5 2.53125V33.4688C4.5 34.868 5.66719 36 7.06641 36H28.9688C30.368 36 31.4648 34.868 31.4648 33.4688V12.3926C31.4648 11.7246 31.2187 11.0848 30.7477 10.6102ZM20.4961 3.88125L27.5555 10.9688H20.4961V3.88125ZM28.9336 33.4688H7.03125V2.53125H17.9648V10.9688C17.9648 12.368 19.132 13.5 20.5312 13.5H28.9336V33.4688Z'
          />
          <path
            fill='var(--charcoal-200, #87878A)'
            d='M10.24 25.89V24.21H13.05V23.15H10.24V21.51H13.81V20.4H8.86V27H13.95V25.89H10.24ZM19.3381 27H20.8881L19.1481 24.32C20.0081 24.05 20.4981 23.39 20.4981 22.44C20.4981 21.14 19.5981 20.4 18.0981 20.4H15.1881V27H16.5481V24.47H17.7881L19.3381 27ZM16.5481 21.5H17.9181C18.7181 21.5 19.0881 21.8 19.0881 22.46C19.0881 23.12 18.7181 23.42 17.9181 23.42H16.5481V21.5ZM26.285 27H27.715L25.305 20.4H23.655L21.245 27H22.655L23.195 25.39H25.745L26.285 27ZM23.545 24.37L24.175 22.49L24.455 21.51H24.475L24.775 22.51L25.405 24.37H23.545Z'
          />
        </svg>
      ),
      key: 'Era',
      label: 'ERAs',
    },
  ]

  const mapServiceFilterItem = (serviceKey: string) => {
    const serviceFilter = serviceFilterItems.find(
      (item) => item.key === serviceKey,
    ) as any

    return (
      <Row align='middle'>
        <Col className={`${styles['carrier-service-filter__tag-icon']} mr-025`}>
          {serviceFilter.icon}
        </Col>
        <Col>{serviceFilter.label}</Col>
      </Row>
    )
  }

  return (
    <Row
      align='middle'
      gutter={16}
    >
      <Col>
        <Dropdown
          disabled={loading}
          menu={{
            className: styles['carrier-service-filter__menu'],
            items: serviceFilterItems,
            onClick: ({ key }) =>
              setFilters({ ...filters, service: key as keyof CarrierDto }),
          }}
          placement='bottom'
          trigger={['click']}
        >
          <VyneButton
            dataTestId='service-filter-button'
            icon={<DownOutlined />}
            type='default'
          >
            Filter by Service
          </VyneButton>
        </Dropdown>
      </Col>
      {filters.service && (
        <Col>
          <Tag
            className={styles['carrier-service-filter__tag']}
            closable
            key={`tag-${filters.service}`}
            onClose={() => setFilters({ ...filters, service: null })}
          >
            {mapServiceFilterItem(filters.service)}
          </Tag>
        </Col>
      )}
    </Row>
  )
}

export default ServiceFilter
