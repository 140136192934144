export const validateAlphaNumeric = (value: string) => {
  const isValid =
    /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/.test(value)

  let error: string | null = null
  if (!isValid) error = 'Must be alphanumeric characters'

  if (error) return Promise.reject(new Error(error))
  else return Promise.resolve()
}
