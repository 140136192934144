import { observer } from '@legendapp/state/react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ErrorCodes, LogError } from 'utils'

import { UpdateBillingDetails } from 'trellis:api/practice/practiceApi'
import { NotifyText } from 'trellis:constants/notifyText'
import { stateSelectOptions } from 'trellis:constants/stateSelect'
import { logoutUser } from 'trellis:features/authentication/Login/utils/login-helpers'
import { GlobalState, SaveBillingRequest } from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'
import { validateProperty } from 'trellis:utilities/validators/baseValidator'
import { validateCreditCard } from 'trellis:utilities/validators/creditCardValidator'
import { validateExpirationMonth } from 'trellis:utilities/validators/expirationDateValidator'
import { validateZip } from 'trellis:utilities/validators/zipValidator'

import { validateRequiredFields } from '../../form/util'
import { InvoiceContextType, useInvoiceContext } from './invoiceContext'

type PaymentContextType = {
  // state
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  payment: any
  setPayment: any
  saving: boolean
  isNew: boolean
  setIsNew: Dispatch<SetStateAction<boolean>>

  // functions
  handleLogout: () => void
  handleSavePayment: () => void
  getMonthOptions: () => any[]
  getYearOptions: () => any[]
  resetForm: () => void
  onSubmit: (formData: any) => Promise<void>
  onFail: (formData: any) => void
} & InvoiceContextType

type PaymentDetailsFormData = {
  cardType?: string
  lastFour?: string
  expDate?: string
  facilityId?: string
  email?: string
  billToName?: string
  billingAddress?: string
  billingCity?: string
  billingState?: string
  billingZip?: string
  creditCardType?: string
  creditCardNumber?: string
  creditCardExpireYear?: string
  creditCardExpireMonth?: string
  errors?: []
  isValid?: boolean
}

const PaymentContext = createContext<PaymentContextType>(null)

const initialFormData: PaymentDetailsFormData = {
  cardType: null,
  lastFour: null,
  expDate: null,
  facilityId: null,
  email: null,
  billToName: null,
  billingAddress: null,
  billingCity: null,
  billingState: null,
  billingZip: null,
  creditCardType: null,
  creditCardNumber: null,
  creditCardExpireYear: null,
  creditCardExpireMonth: null,
  errors: [],
}

const nonRequiredFields: string[] = [
  'cardType',
  'lastFour',
  'expDate',
  'facilityId',
  'email',
  'error',
  'authToken',
  'activeServices',
  'registrationToken',
  'message',
]

export const PaymentContextProvider = observer(({ children }: any) => {
  const facilityId = GlobalState.PracticeInfo.facilityID.get()
  const customerEmail = GlobalState.UserInfo.userName.get()

  const navigate = useNavigate()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [payment, setPayment] =
    useState<PaymentDetailsFormData>(initialFormData)
  const [saving, isSaving] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const ldClient = useLDClient()

  const {
    selectedInvoice,
    selectedTin,
    invoices,
    invoicesLoading,
    setInvoicesLoading,
    updateSelectedTin,
    refreshInvoices,
    saveReceipt,
    getReceipt,
  } = useInvoiceContext()

  const resetForm = () => {
    setPayment(initialFormData)
  }

  const onSubmit = async (formData: any) => {
    const updatedPayment = validateForm(formData)
    if (!updatedPayment.isValid) return

    await savePayment()
  }

  const onFail = (formData: any) => {
    validateForm(formData.values)
    showMessage(NotifyText.paymentError)
  }

  //TODO: Remove this logout method
  const handleLogout = async () => {
    setLoading(true)

    try {
      await logoutUser(ldClient)
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error?.message != ErrorCodes.auth_refresh_failed &&
        error?.response?.status != 403
      )
        LogError(error, 'Error logging out')
    } finally {
      setLoading(false)
      GlobalState.IsAuthenticated.set(false)
      navigate('/Account/Login', { replace: true })
    }
  }

  const handleSavePayment = () => {
    isSaving(true)
    validateForm(payment)
    if (payment.isValid) savePayment()
  }

  const validateForm = (formData: any) => {
    formData.isValid = true
    formData.errors = []
    validateRequiredFields(formData, nonRequiredFields)
    validateProperty(validateZip, formData, 'billingZip', null, true)
    validateProperty(
      validateCreditCard,
      formData,
      'creditCardNumber',
      null,
      true,
    )
    validateProperty(
      validateExpirationMonth,
      formData,
      'creditCardExpireMonth',
      null,
      true,
    )

    if (formData.errors && formData.errors.length) {
      isSaving(false)
      showMessage(NotifyText.paymentError)
      formData.isValid = false
    }

    const updatedPayment = { ...payment, ...formData }
    setPayment(updatedPayment)
    return updatedPayment
  }

  const savePayment = () => {
    isSaving(true)
    const req = tokenizeCardRequest()
    req.email = customerEmail
    req.facilityId = facilityId
    const lastFour = req.creditCardNumber.slice(-4)
    api
      .tokenizeCard(req)
      .then(({ data }) => {
        savePaymentInfo(data, lastFour)
      })
      .catch(() => {
        showMessage('Unable to tokenize card, invalid account number.')
      })
  }

  const savePaymentInfo = (tokenInfo: any, lastFour: string) => {
    const req: SaveBillingRequest = paymentRequest(tokenInfo, lastFour)

    UpdateBillingDetails(req)
      .then(({ data }) => {
        setPayment(data)
        showMessage('Payment method has successfully updated!', 'success')

        GlobalState.BillingDetails.cardType.set(data.cardType)
        GlobalState.BillingDetails.expDate.set(data.expDate)
        GlobalState.BillingDetails.lastFour.set(data.lastFour)
        GlobalState.BillingDetails.paymentType.set(data.paymentType)

        if (window.location.pathname === '/Account/LoginPaymentInfo') {
          const redirectURL = location?.state?.redirectURL

          if (redirectURL) {
            navigate(redirectURL, { replace: true })
          } else {
            navigate(RoleHelper$.defaultRoute.peek(), { replace: true })
          }
        }
      })
      .catch(() => {
        showMessage('Unable to update payment method. Please try again later.')
      })
      .finally(() => {
        isSaving(false)
      })
  }

  const getMonthOptions = () => {
    const months = []
    for (let i = 1; i <= 12; i++) {
      const month = i < 10 ? `0${i}` : `${i}`
      months.push({ value: month, text: month })
    }

    return months
  }

  const getYearOptions = () => {
    const years = []
    const currentYear = parseInt(format(new Date(), 'yyyy'))
    for (let i = 0; i <= 9; i++) {
      years.push({ value: `${currentYear + i}`, text: `${currentYear + i}` })
    }
    return years
  }

  const tokenizeCardRequest = () => {
    let stateAbbreviation: any = stateSelectOptions.filter(
      (s: any) => s.value === payment.billingState,
    )[0]
    stateAbbreviation = stateAbbreviation && stateAbbreviation.value
    return {
      facilityId: payment.facilityId,
      email: payment.email,
      billToName: payment.billToName,
      address: payment.billingAddress,
      city: payment.billingCity,
      stateAbbreviation: stateAbbreviation,
      zip: payment.billingZip,
      creditCardType: payment.creditCardType,
      creditCardNumber: payment.creditCardNumber,
      creditCardExpireYear: payment.creditCardExpireYear,
      creditCardExpireMonth: payment.creditCardExpireMonth,
    }
  }

  const paymentRequest = (tokenInfo: any, lastFour: string) => {
    return {
      paymentType: 'CREDIT CARD',
      token: tokenInfo.token,
      billToName: tokenInfo.billToName,
      creditCardType: tokenInfo.creditCardType,
      creditCardExpireYear: tokenInfo.creditCardExpireYear,
      creditCardExpireMonth: tokenInfo.creditCardExpireMonth,
      lastFour: lastFour,
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        // state
        loading,
        setLoading,
        payment,
        setPayment,
        saving,
        isNew,
        setIsNew,
        selectedInvoice,
        selectedTin,
        invoices,
        invoicesLoading,
        setInvoicesLoading,

        // functions
        handleLogout,
        handleSavePayment,
        getMonthOptions,
        getYearOptions,
        resetForm,
        onSubmit,
        onFail,
        updateSelectedTin,
        saveReceipt,
        getReceipt,
        refreshInvoices,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
})

export const usePaymentContext = () => {
  const context = useContext(PaymentContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
