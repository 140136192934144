import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { FC } from 'react'

import './sendAttachmentModal.scss'

import { VyneButton } from '@vynedental/design-system'

import { useClaimDetailContext } from '../../context/claimDetailContext'

type SendAttachmentModalProps = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SendAttachmentModal: FC<SendAttachmentModalProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const { submitSolicitedAttachment } = useClaimDetailContext()
  const handleSolicitedAttachments = () => {
    submitSolicitedAttachment()
    setIsModalOpen(false)
  }

  const sendAttachmentTitle: JSX.Element = (
    <div className='attachment-modal__title-container'>
      <InfoCircleOutlined className='attachment-modal__info-icon' />
      <p className='attachment-modal__title'>Submit Solicited Attachment</p>
    </div>
  )

  const footerElements: JSX.Element[] = [
    <VyneButton
      dataTestId='cancel-send-attachment-button'
      key='cancel'
      onClick={() => setIsModalOpen(false)}
    >
      Cancel
    </VyneButton>,
    <VyneButton
      dataTestId='submit-send-attachment-button'
      key='submit'
      onClick={handleSolicitedAttachments}
      type='primary'
    >
      Submit
    </VyneButton>,
  ]

  return (
    <Modal
      title={sendAttachmentTitle}
      onCancel={() => setIsModalOpen(false)}
      open={isModalOpen}
      footer={footerElements}
      className='attachment-modal'
      key='send-attachment-modal'
    >
      <p className='attachment-modal__body'>
        This attachment will be sent without re-sending the claim.
      </p>
    </Modal>
  )
}

export default SendAttachmentModal
