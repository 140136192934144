import { SyncOutlined } from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import { VyneButton } from '@vynedental/design-system'
import { FC, useState } from 'react'

import { LDFlags$ } from 'trellis:state/globalState'

import { PearlConfiguration } from '../../../api/pearl/pearl-client/api'
import { GetCustomerSettings } from '../../../api/pearl/pearlApiService'
import { useGlobalContext } from '../../../context/GlobalContextProvider'
import ImageSyncNotConfiguredModal from './imageConfiguration/imageSyncNotConfiguredModal'
import ImageSyncPearlProductModal from './imageConfiguration/imageSyncPearlProductModal'
import ImageListModal from './imageList/imageListModal'
import { ImageSyncProps } from './imageSync.interface'

const ImageSync: FC<ImageSyncProps> = observer(
  ({
    attachmentInformation,
    updateAttachmentImages,
    handleAttachmentImageAssembly,
  }) => {
    const [isImageSyncListVisible, setImageSyncListVisible] =
      useState<boolean>(false)
    const [isImageSyncNotConfiguredVisible, setImageSyncNotConfiguredVisible] =
      useState<boolean>(false)
    const [isImageSyncPearlProductVisible, setImageSyncPearlProductVisible] =
      useState<boolean>(false)
    const [customerSettings, setCustomerSettings] =
      useState<PearlConfiguration>(null)

    const { authentication: trellisAuthentication, decodedJWT } =
      useGlobalContext()
    const { pearlImageSyncDisplay } = LDFlags$.get()

    const getCustomerSettings = async () => {
      if (!decodedJWT?.active_services?.includes('PEARL_IMAGE_SYNC')) {
        setImageSyncPearlProductVisible(!isImageSyncPearlProductVisible)
        return
      }

      let settings = {} as PearlConfiguration
      try {
        settings = await GetCustomerSettings(
          trellisAuthentication,
          trellisAuthentication.CustomerId,
        )
      } catch (error) {}

      if (!settings) {
        setImageSyncNotConfiguredVisible(!isImageSyncNotConfiguredVisible)
      } else {
        setCustomerSettings(settings)
        setImageSyncListVisible(!isImageSyncListVisible)
      }
    }

    return (
      <>
        {pearlImageSyncDisplay && (
          <VyneButton
            className='image-sync-button'
            dataTestId='image-sync-button'
            icon={<SyncOutlined />}
            onClick={() => {
              getCustomerSettings()
            }}
          >
            Image Sync
          </VyneButton>
        )}
        {isImageSyncListVisible && (
          <ImageListModal
            customerSettings={customerSettings}
            attachmentInformation={attachmentInformation}
            updateAttachmentImages={updateAttachmentImages}
            handleAttachmentImageAssembly={handleAttachmentImageAssembly}
            isVisible={isImageSyncListVisible}
            setIsVisible={setImageSyncListVisible}
          />
        )}
        {isImageSyncNotConfiguredVisible && (
          <ImageSyncNotConfiguredModal
            isVisible={isImageSyncNotConfiguredVisible}
            setIsVisible={setImageSyncNotConfiguredVisible}
          />
        )}
        {isImageSyncPearlProductVisible && (
          <ImageSyncPearlProductModal
            isVisible={isImageSyncPearlProductVisible}
            setIsVisible={setImageSyncPearlProductVisible}
          />
        )}
      </>
    )
  },
)

export default ImageSync
