import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Form, message } from 'antd'
import { useEffect, useState } from 'react'

import { useGlobalContext } from 'trellis:context/GlobalContextProvider'
import GlobalState from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'
import { validateName } from 'trellis:utilities/validators/nameValidator'

import { FormInput } from '../../_siteWide/form/formInput'
import { Loading } from '../../_siteWide/loader/dataDisplay'

import './changeFirstLastName.scss'

import { observer } from '@legendapp/state/react'

const defaultName: any = {
  cached: {
    first: '',
    last: '',
  },
  first: '',
  last: '',
  errors: [],
}

export const FirstLastName = observer(() => {
  const { userInfo } = useGlobalContext()

  const [editNameActive, setEditNameActive] = useState(false)
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState({ ...defaultName })
  const errorExists = name.errors.length > 0
  const noNameChanges =
    name?.first?.trim() === name.cached.first &&
    name?.last?.trim() === name.cached.last

  const [form] = Form.useForm()
  form.setFieldsValue({ firstName: name.first, lastName: name.last })

  const enableEditUser = () => {
    setEditNameActive(true)
  }

  const disableEditUser = () => {
    setEditNameActive(false)
    setName({
      ...name,
      first: name.cached.first,
      last: name.cached.last,
      errors: [],
    })
  }

  const confirmUserNameChange = () => {
    if (noNameChanges) {
      showMessage('Please make name changes to update.')
      return
    }

    if (name.first === '') {
      showMessage('First name field cannot be left blank.')
      return
    } else if (name.last === '') {
      showMessage('Last name field cannot be left blank')
      return
    }

    setLoading(true)

    api
      .updateUserFirstAndLastName(GlobalState.UserInfo.customerUserID.get(), {
        firstName: name.first,
        lastName: name.last,
      })
      .then(() => {
        message.success('Name has been updated.')
        setName({ ...name, cached: { first: name.first, last: name.last } })
        GlobalState.UserInfo.set({
          ...GlobalState.UserInfo.get(),
          firstName: name.first,
          lastName: name.last,
        })
      })
      .catch(() =>
        showMessage('There was an error updating first and last name.'),
      )
      .finally(() => {
        setEditNameActive(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (userInfo) {
      setName({
        ...defaultName,
        cached: {
          first: userInfo.firstName || '',
          last: userInfo.lastName || '',
        },
        first: userInfo.firstName || '',
        last: userInfo.lastName || '',
      })
      form.setFieldsValue({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      })
      setLoading(false)
    }
  }, [userInfo])

  return (
    <div className='change-name-container'>
      <p className='sub-section-title change-name-title'>Name</p>
      <Form
        form={form}
        name='ChangeName'
        className='change-name-form'
      >
        {loading && <Loading />}
        <section className='change-name-controls'>
          <FormInput
            className={`last-name ${editNameActive && 'active'}`}
            name='last'
            label='Last'
            element={name}
            setElement={setName}
            initialValue={name.last}
            disabled={!editNameActive}
            validator={validateName}
            required
          />
          <FormInput
            className={`first-name ${editNameActive && 'active'}`}
            name='first'
            label='First'
            element={name}
            setElement={setName}
            initialValue={name.first}
            validator={validateName}
            disabled={!editNameActive}
            required
          />
          <div className='btns-container'>
            {editNameActive ? (
              <>
                <button onClick={disableEditUser}>
                  <CloseOutlined />
                </button>
                <button
                  onClick={() => confirmUserNameChange()}
                  disabled={errorExists ? true : false}
                >
                  <CheckOutlined className={errorExists ? 'error' : ''} />
                </button>
              </>
            ) : (
              <button onClick={enableEditUser}>
                <EditOutlined />
              </button>
            )}
          </div>
        </section>
      </Form>
    </div>
  )
})
