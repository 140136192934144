import { observer } from '@legendapp/state/react'
import { Tabs, VyneButton } from '@vynedental/design-system'
import { FC, useEffect, useState } from 'react'

import { FormError } from 'utils'

import { BillingAndSubscription } from 'trellis:components/billingAndSubscription/billingAndSubscription'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import LegalBusinessInfo from './tabs/LegalBusinessInfo/LegalBusinessInfo'
import { PMSCustomerToken } from './tabs/PMSCustomerToken/PmsCustomerToken'
import PracticeInfo from './tabs/PracticeInfo/PracticeInfo'

import './MyPractice.scss'

export const TAB_NAMES = {
  PRACTICE_INFO: 'Practice Info',
  LEGAL_BUSINESS_INFO: 'Legal Business Info',
  BILLING_AND_SUBSCRIPTION: 'Billing & Subscription',
  PMS_CUSTOMER_TOKEN: 'PMS Customer Token',
} as const

export const MyPractice: FC = observer(() => {
  const flags = LDFlags$.get()
  const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()
  const billingSubscriptionNav = GlobalState.BillingSubscriptionNav.get()
  const practiceInfo = GlobalState.PracticeInfo.get()
  const decodedJWT = GlobalState.DecodedJwt.get()
  const [activeTab, setActiveTab] = useState<string>(TAB_NAMES.PRACTICE_INFO)
  const [formName, setFormName] = useState<string>('practiceInfoForm')
  const [legalBusinessInfoFormErrors, setLegalBusinessInfoFormErrors] =
    useState<FormError[]>([])
  const [practiceInfoFormErrors, setPracticeInfoFormErrors] = useState<
    FormError[]
  >([])
  const [resetPracticeInfoForm, setResetPracticeInfoForm] =
    useState<boolean>(false)
  const [saving, isSaving] = useState<boolean>(false)

  useEffect(() => {
    if (billingSubscriptionNav) {
      setFormName('billingAndSubForm')
      setActiveTab(TAB_NAMES.BILLING_AND_SUBSCRIPTION)
    }
  }, [billingSubscriptionNav])

  useEffect(() => {
    const hasValidLegalBusinessInfo: boolean =
      legalBusinessStatus?.verificationStatus === 1 ||
      legalBusinessStatus?.verificationStatus === 2
    const hasOperaCompanyId: boolean = !!decodedJWT?.opera_company_id
    const isDI: boolean = decodedJWT?.active_services.includes('DENTAL_INTEL')
    if (
      !isDI &&
      hasOperaCompanyId &&
      !hasValidLegalBusinessInfo &&
      !billingSubscriptionNav
    ) {
      setFormName('legalBusinessInfoForm')
      setActiveTab(TAB_NAMES.LEGAL_BUSINESS_INFO)
    }
  }, [legalBusinessStatus])

  const handleTabChange = (tabKey: string) => {
    switch (tabKey) {
      case TAB_NAMES.PRACTICE_INFO:
        setFormName('practiceInfoForm')
        break
      case TAB_NAMES.LEGAL_BUSINESS_INFO:
        setFormName('legalBusinessInfoForm')
        break
      case TAB_NAMES.BILLING_AND_SUBSCRIPTION:
        setFormName('billingAndSubForm')
        break
      default:
        break
    }
    setActiveTab(tabKey)
  }

  const items = [
    {
      label: `Practice Info`,
      key: 'Practice Info',
      children: (
        <PracticeInfo
          formErrors={practiceInfoFormErrors}
          isSaving={isSaving}
          resetForm={resetPracticeInfoForm}
          setFormErrors={setPracticeInfoFormErrors}
        />
      ),
    },
    !decodedJWT?.active_services.includes('DENTAL_INTEL') &&
      decodedJWT?.opera_company_id && {
        label: `Legal Business Info`,
        key: 'Legal Business Info',
        children: (
          <LegalBusinessInfo
            formErrors={legalBusinessInfoFormErrors}
            isSaving={isSaving}
            setFormErrors={setLegalBusinessInfoFormErrors}
          />
        ),
      },
    flags.billingAndSubscription &&
      !decodedJWT?.active_services.includes('DENTAL_INTEL') && {
        label: `Billing & Subscription`,
        key: 'Billing & Subscription',
        children: <BillingAndSubscription activeTab={activeTab} />,
      },
    practiceInfo.partnerClaimsSupported && {
      label: 'PMS Customer Token',
      key: 'PMS Customer Token',
      children: (
        <PMSCustomerToken
          data-testId='pms-customer-token-tab'
          practiceInfo={practiceInfo}
        />
      ),
    },
  ]

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <section className='page-content-header__container'>
        <h1 className='page-content-header__title'>My Practice</h1>
        <div className='page-content-header__button-container'>
          {activeTab === TAB_NAMES.PRACTICE_INFO && (
            <VyneButton
              className='mr-100'
              dataTestId='reset-practice-info-button'
              onClick={() => setResetPracticeInfoForm(true)}
              size='large'
            >
              Reset
            </VyneButton>
          )}
          {activeTab !== TAB_NAMES.PMS_CUSTOMER_TOKEN &&
            activeTab !== TAB_NAMES.BILLING_AND_SUBSCRIPTION && (
              <VyneButton
                dataTestId='submit-practice-info-button'
                form={formName}
                htmlType='submit'
                loading={saving}
                size='large'
                type='primary'
              >
                Submit
              </VyneButton>
            )}
        </div>
      </section>
      <Tabs
        activeKey={activeTab}
        className='pt-100'
        items={items}
        name='my-practice-tabs'
        onChange={(key: string) => handleTabChange(key)}
      />
    </article>
  )
})
