import { SelectOption } from '../constants'

export const validateCarrier = (
  value: string,
  carrierOptions: SelectOption[],
): Promise<string | void> => {
  const match = carrierOptions.find(
    (carrier: SelectOption) =>
      (carrier.value as string)?.toLowerCase()?.trim() ===
      value?.toLowerCase()?.trim(),
  )

  if (!match)
    return Promise.reject(
      new Error('Invalid Carrier. Please select a Carrier from the list.'),
    )
  else return Promise.resolve()
}
