import {
  DeleteOutlined,
  EllipsisOutlined,
  PauseCircleOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { VyneButton } from '@vynedental/design-system'
import { Popover } from 'antd'
import { useState } from 'react'

import {
  deleteSelectedAttachments,
  holdSelectedAttachments,
  refreshSentAttachmentGrid,
  refreshUnsentAttachmentGrid,
  sendSelectedAttachments,
} from '../AttachmentsGrid/_attachmentGridServices'
import { AttachmentData } from '../AttachmentsTypes'
import { useAttachmentsContext } from '../Context/AttachmentsContext'

interface AttachmentRowActionsMenuProps<TableItem> {
  index: number
  tableItem: TableItem
  sentDates: [Date, Date]
}

const AttachmentRowActionsMenu = <TableItem extends AttachmentData>({
  index,
  tableItem,
  sentDates,
}: AttachmentRowActionsMenuProps<TableItem>) => {
  const {
    facilityId,
    setActiveTab,
    setAttachments,
    setSentAttachments,
    setGridLoading,
    getSortedAttachments,
  } = useAttachmentsContext()

  const [showingMenuPopover, setshowingMenuPopover] = useState(false)

  const selectedAttachmentId: any[] = []
  selectedAttachmentId.push(tableItem.attachmentId)

  const handleScroll = () => {
    const actionsMenu = document.querySelector(`.ant-popover`)
    if (actionsMenu) setshowingMenuPopover(false)
  }

  const handleShowMenu = (isVisible: boolean) => {
    setshowingMenuPopover(isVisible)

    const pageContent = document.querySelector('.page-content__container')
    if (pageContent) pageContent.addEventListener('scroll', handleScroll)
  }

  const handleCloseMenu = () => {
    setshowingMenuPopover(false)

    const pageContent = document.querySelector('.page-content__container')
    if (pageContent) pageContent.removeEventListener('scroll', handleScroll)
  }

  const rowActionCleanup = (action: string) => {
    refreshUnsentAttachmentGrid(
      facilityId,
      setAttachments,
      getSortedAttachments,
    )
    setGridLoading(false)
    if (action.toString() === 'Send') {
      setGridLoading(true)
      setActiveTab('Sent')
      refreshSentAttachmentGrid(
        facilityId,
        setSentAttachments,
        sentDates,
        getSortedAttachments,
      )
      setGridLoading(false)
    }
  }

  const menuContent: JSX.Element = (
    <div>
      <p className='attachment-row-actions__menu-item'>
        <VyneButton
          dataTestId='send-attachment-button'
          icon={<SendOutlined style={{ color: 'green' }} />}
          onClick={async () => {
            handleCloseMenu()
            setGridLoading(true)
            await sendSelectedAttachments(selectedAttachmentId)
            rowActionCleanup('Send')
          }}
          type='text'
        >
          Send
        </VyneButton>
      </p>
      <p className='attachment-row-actions__menu-item'>
        <VyneButton
          className='attachment-row-actions__menu-item'
          dataTestId='hold-attachment-button'
          icon={<PauseCircleOutlined style={{ color: 'blue' }} />}
          onClick={async () => {
            handleCloseMenu()
            setGridLoading(true)
            await holdSelectedAttachments(selectedAttachmentId)
            rowActionCleanup('Hold')
          }}
          type='text'
        >
          Hold
        </VyneButton>
      </p>
      <p className='attachment-row-actions__menu-item'>
        <VyneButton
          className='attachment-row-actions__menu-item'
          dataTestId='delete-attachment-button'
          icon={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={async () => {
            handleCloseMenu()
            setGridLoading(true)
            await deleteSelectedAttachments(selectedAttachmentId)
            rowActionCleanup('Delete')
          }}
          type='text'
        >
          Delete
        </VyneButton>
      </p>
    </div>
  )

  return (
    <section
      aria-label='open row actions'
      className='attachment-row-actions'
      id={`attachment-row-actions__trigger--${index}`}
    >
      <Popover
        placement='leftTop'
        content={menuContent}
        trigger='click'
        open={showingMenuPopover}
        onOpenChange={(isVisible: boolean) => handleShowMenu(isVisible)}
        overlayStyle={{
          width: '150px',
        }}
      >
        <EllipsisOutlined
          rotate={90}
          style={{ fontSize: '1.5em' }}
        />
      </Popover>
    </section>
  )
}

export default AttachmentRowActionsMenu
