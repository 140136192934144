import { DatePicker, RangePickerProps } from '@vynedental/design-system'
import { FC } from 'react'

import { AttachmentDatePickerConstants } from 'trellis:components/attachmentsShared/shared/sharedConstants'

import { ImageDataProps } from '../AttachmentsTypes'

import './ImageDateTaken.scss'

import dayjs from 'dayjs'

import { themeConfig } from 'trellis:utilities/datePickerUtilities'

type ImageDateTakenProps = {
  record: ImageDataProps
  updateImageData: any
  index?: number
  rowId?: number
}
const ImageDateTaken: FC<ImageDateTakenProps> = ({
  record,
  updateImageData,
  index = 0,
}) => {
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      (current && current > dayjs().endOf('day')) ||
      current < dayjs(AttachmentDatePickerConstants.MINIMUM_DISABLED_DATE)
    )
  }

  return (
    <DatePicker
      disabledDate={disabledDate}
      onChange={(_date, dateString: string | string[]) =>
        updateImageData('DateTaken', dateString, index)
      }
      name='attachment-image-date-picker'
      placeholder='MM/DD/YYYY'
      theme={themeConfig}
      value={record.dateTaken && dayjs(record.dateTaken)}
    />
  )
}

export default ImageDateTaken
