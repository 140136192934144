import { LaptopOutlined } from '@ant-design/icons'
import { VyneButton } from '@vynedental/design-system'
import { message, Modal } from 'antd'
import React, { ClipboardEvent, FC, useEffect, useState } from 'react'

import { ImageCaptureMethod, ImageQuality } from '../../../attachment.interface'

import '../../../../Attachments/AttachmentView/KeyboardCapture.scss'

import { IImageCaptureClaimAttachment } from '../../../../attachmentsShared/shared/shared.interface'
import {
  FileExtensions,
  MimeTypes,
} from '../../../../attachmentsShared/shared/shared.types'
import { canvasImageConversion } from '../../../../attachmentsShared/shared/sharedFunctions'

const KeyboardCapture: FC<IImageCaptureClaimAttachment> = ({
  updateAttachmentImage,
}) => {
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false)

  const handlePaste = async (e: ClipboardEvent) => {
    if (visibleDialog && e.clipboardData.files.length) {
      const imageData = e.clipboardData.files[0]
      if (imageData.type.indexOf('image') > -1) {
        const filename = new Date().getTime() + FileExtensions.JPG
        const convertedFile: File = await canvasImageConversion(
          imageData,
          filename,
          MimeTypes.ImageJpeg,
          ImageQuality.MaxUnity,
        )
        updateAttachmentImage(
          {
            isNewImage: true,
            imageData: convertedFile,
            imageDateTaken: new Date(),
            imageCaptureMethod: ImageCaptureMethod.KeyboardCapture,
          },
          false,
        )
        setVisibleDialog(false)
      } else {
        //File is not an image type. type = ', imageData.type
        message.error('File is not an image type: ' + imageData.name)
      }
    } else if (visibleDialog) {
      //No file data was found in clipboard
      message.error('No file data was found in clipboard')
    }
  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste as unknown as EventListener)

    return () => {
      window.removeEventListener(
        'paste',
        handlePaste as unknown as EventListener,
      )
    }
  }, [visibleDialog])

  return (
    <>
      <VyneButton
        className='keyboard-capture-btn'
        dataTestId='keyboard-capture-button'
        icon={<LaptopOutlined />}
        onClick={() => setVisibleDialog(true)}
      >
        Keyboard Capture
      </VyneButton>

      {visibleDialog && (
        <Modal
          title={
            <p className='sa-keyboard-capture-modal__title'>Keyboard Capture</p>
          }
          onCancel={() => setVisibleDialog(false)}
          destroyOnClose={true}
          open={visibleDialog}
          footer={null}
          className={'sa-keyboard-capture-modal'}
        >
          <div className='sa-keyboard-capture-modal__acquisition'>
            <div className='sa-keyboard-capture-modal__acquisition-header'>
              <b>Capture an image using keyboard shortcuts.</b>
            </div>
            <div>
              Upload images using the following steps:
              <ul className='sa-keyboard-capture-modal__list'>
                <li>Click on the window you want to capture.</li>
                <li>
                  Press the buttons, <b>"alt" + "Print Screen."</b>
                </li>
                <li>Return to this window.</li>
                <li>
                  Press the <b>"ctrl" + "v"</b> to upload the image.
                </li>
              </ul>
            </div>
          </div>
          <div className='sa-keyboard-capture-modal__acquisition-note'>
            *Keep this window open when using the capture tool.
          </div>
        </Modal>
      )}
    </>
  )
}

export default KeyboardCapture
