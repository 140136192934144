import { ChangeEvent, FormEvent, useState } from 'react'

import { isEmptyValue } from '../functions'

export type ValidatorReturn = {
  isValid: boolean
  message: string | null
}

export interface Validation {
  required?: {
    message: string
    value: boolean
  }
  validator?: (value: string) => ValidatorReturn
}

export type Validations<DataType> = Partial<Record<keyof DataType, Validation>>

export type ErrorRecord<DataType> = Partial<Record<keyof DataType, string>>

interface UseFormOptions<DataType> {
  initialValues?: Partial<DataType>
  onSubmit?: () => void
  validations?: Validations<DataType>
}

// falls back to empty object if generic type isn't passed
export const useForm = <DataType extends Record<keyof DataType, unknown> = {}>({
  initialValues,
  onSubmit,
}: UseFormOptions<DataType>) => {
  const [formData, setFormData] = useState<DataType>(
    (initialValues || {}) as DataType,
  )
  const [formErrors, setFormErrors] = useState<ErrorRecord<DataType>>({})
  const [formValidations, setFormValidations] = useState<Validations<DataType>>(
    {},
  )
  const [isValidationMode, setIsValidationMode] = useState<boolean>(false)

  const handleFormChange =
    <S extends unknown>(
      key: keyof DataType,
      sanitizeFn?: (value: string) => S,
    ) =>
    (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value

      setFormData({
        ...formData,
        [key]: value,
      })

      if (isValidationMode) {
        const validation = formValidations[key]
        const errorsCopy: ErrorRecord<DataType> = { ...formErrors }

        const errorMessage = validateField(validation, value?.toString() || '')

        if (errorMessage) {
          errorsCopy[key] = errorMessage
          setIsValidationMode(true)
          setFormErrors(errorsCopy)
        } else {
          delete errorsCopy[key]
          setFormErrors(errorsCopy)
        }
      }
    }

  const validateField = (validation: Validation | undefined, value: string) => {
    if (validation?.required?.value && isEmptyValue(value)) {
      return validation?.required?.message
    } else if (value && validation?.validator) {
      const validatorRes = validation?.validator(value)
      if (validatorRes && !validatorRes?.isValid) {
        return validatorRes.message
      }
    }

    return null
  }

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (formValidations) {
      let isValid: boolean = true
      const newErrors: ErrorRecord<DataType> = {}

      for (const key in formValidations) {
        const value = formData[key]

        const validation = formValidations[key]

        const error = validateField(validation, value?.toString() || '')

        if (error) {
          isValid = false
          newErrors[key] = error
        }
      }
      if (!isValid) {
        setIsValidationMode(true)
        setFormErrors(newErrors)
        return
      }
    }

    setFormErrors({})

    if (onSubmit) onSubmit()
  }

  return {
    formData,
    formErrors,
    handleFormChange,
    handleFormSubmit,
    setFormValidations,
  }
}
