import { VyneSwitch } from '@vynedental/design-system'
import { Form } from 'antd'
import React from 'react'

export const FormSwitch = ({
  dataTestId,
  name,
  checked,
  disabled,
  checkedChildren = 'YES',
  unCheckedChildren = 'NO',
  onChange,
  ...others
}: any) => {
  return (
    <Form.Item name={name}>
      <VyneSwitch
        dataTestId={dataTestId || `${name}-form-switch`}
        checked={checked}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={() => onChange()}
        disabled={disabled}
        {...others}
      />
    </Form.Item>
  )
}
